import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import Robot from "./images/Circle-logo.svg";

import BGMain from "./images/BGMain.svg";
import BGMobile from "./images/BGMobile.svg";
import { HelpEmailLink } from "./HelpEmailLink";

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 122,
    backgroundImage: `url(${BGMain})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${BGMobile})`,
    },
  },
  gridContainer: {},
  textContainer: {},
  avatar: {
    margin: "118px auto 0px",
  },
  name: {
    fontFamily: "Fira Sans",
    fontSize: 17,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
  },
  date: {
    fontFamily: "Fira Sans",
    fontSize: 15,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#b3b8b3",
    textAlign: "center",
    marginBottom: 94,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  header: {
    fontSize: 37,
    margin: "24px 7px 30px 7px",

    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      textAlign: "center",
      margin: "24px 7px 13px 7px",
    },
  },
  text: {
    margin: "10px 7px 15px 7px",
    fontFamily: "Fira Sans",
    fontSize: 17,
    lineHeight: 1.7,
    letterSpacing: "normal",
    color: "#60656f",
  },
  mobileAvatarContainer: {
    display: "flex",
  },
  mobileNameContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 17px",
  },
  mobileAvatar: {
    marginLeft: 5,
  },
  mobileName: {
    fontFamily: "Fira Sans",
    fontSize: 16,
    lineHeight: 2,
    color: "#000",
  },
  mobileDate: {
    fontFamily: "Fira Sans",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#b3b8b3",
  },
}));

export const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer} container>
        <Grid item xs={false} sm={1}></Grid>
        <Grid className={classes.avatarContainer} item xs={12} sm={2}>
          <Hidden xsDown>
            <img 
            className={classes.avatar}
              src={Robot}
              width="80px"
              height="80px" 
              alt="" 
            />
            <Typography className={classes.name} variant="body1">
              Last Updated
            </Typography>
            <Typography className={classes.date} variant="body1">
              November 23, 2020
            </Typography>
          </Hidden>
        </Grid>
        <Grid className={classes.textContainer} item xs={12} sm={6}>
          <Typography className={classes.header} variant="h2">
            Privacy Policy - Readtronic
          </Typography>

          <Hidden smUp>
            <div className={classes.mobileAvatarContainer}>
              <img
                className={classes.mobileAvatar}
                src={Robot}
                width="50px"
                height="50px"
                alt=""
              />
              <div className={classes.mobileNameContainer}>
                <Typography className={classes.mobileName} variant="body1">
                  Last Updated
                </Typography>
                <Typography className={classes.mobileDate} variant="body1">
                  November 23, 2020
                </Typography>
              </div>
            </div>
          </Hidden>

          <Typography className={classes.text} variant="body1">
          <p>The Readtronic Site located at www.Readtronic.com (the “Site”),the Readtronic Mobile Applications (the “App”) and the Readtronic browser extension (“extension”) collectively referred to as “Platform” are owned by Readtronic LLC (“we”, “us”, “Readtronic” and “our”) and operated from the United States. This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally Identifiable Information’ (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect, or otherwise handle your PII.</p>
          <p>This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. Service means the platform operated by Readtronic LLC.</p>
          <p> For US residents, we adopt this notice to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other US privacy laws.  Any terms defined in the CCPA have the same meaning when used in this Policy. For users from Europe, see below “General Data Protection Regulation of 2018 (GDPR), (EU) 2016/679. </p>
          <p>By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions</p>
          <p><b>PERSONAL INFORMATION WE COLLECT</b></p>
          <p><b>Personal information you provide to us (PII or Personal Data).</b>  Personal information you may provide to us through the Service or otherwise includes:</p>
          <ul> 
                <li>
                <b>Personal data,</b> personal information such as your name, profile picture, email and mailing addresses, phone number.
                </li>
                <li>
                <b>Registration data,</b> such as information that you provide to register for an account or sign up, including the day and month of your birth.
                </li>
                <li>
                <b>Profile data,</b> such as your username and password that you may set to establish an online account with us and your interests and preferences.
                </li>
                <li>
                <b>Marketing data, </b> such as the email address or contact details that we use to send marketing communications and your preferences for receiving communications about our activities, events, sweepstakes and contests.
                </li>
                <li>
                <b>Purchase data,</b> including your order history and information needed to process and fulfill your order, including order details, billing address, and delivery address.
                </li>
                <li>
                <b>Other information</b> that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection. 
                </li>
                </ul>
        <p><b>Automatic collection.</b> We and our service providers may automatically log information about you, your computer or mobile device, and your activity occurring on or through the Site, such as:</p>
          <ul> 
                <li>
                <b>Device data,</b> such as your computer or mobile device operating system type and version number, manufacturer and model, browser type, screen resolution, IP address, the website you visited before browsing our site, and general location information such as city, state or geographic area.
                </li>
                <li>
                <b>Online activity data,</b> such as pages or screens you viewed, how long you spent on a page or screen, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access.
                </li>
                </ul>                
          <p>When you download the Readtronic App, we collect your device ID. As you access your account from the App, we will collect your account activity.</p>
         <p><b>HOW WE USE YOUR PERSONAL INFORMATION</b></p>
          <p>We use your personal information for the following purposes and as otherwise described in this Privacy Policy or at the time of collection:</p>
          <p><b>Service delivery.</b>  We may use your personal information to:</p>
          <ul> 
                <li>provide, operate, and improve the Service, such as to enable you to make purchases of our pro version or any other in-app purchases; 
                </li>
                <li>
                establish and maintain your account on the Service;
                </li>
                <li>
                communicate with you about the Service, including by sending you announcements, updates, security alerts, and support and administrative messages;
                </li>
                <li>
                provide customer support and maintenance for the Service;
                </li>
            </ul>
          
          
          <p><b>Direct Marketing.</b> We may use your personal information to send you Readtronic-related marketing communications as permitted by law.  You will have the ability to opt-out of our marketing and promotional communications as described in the Your Choices section below.</p>
          <p><b>For research and development.</b>We may use your personal information for research and development purposes, including to analyze and improve the Service and our business.</p>
          <p><b>To create anonymous data.</b> We may create aggregated, de-identified, or other anonymous data records from your personal information and other individuals whose personal information we collect.  We make personal information into anonymous data by excluding information (such as your name) that makes the data personally identifiable to you.  We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.</p>
          <p><b>To comply with laws and regulations.</b>  We use your personal information as we believe necessary or appropriate to comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.</p>
          <p><b>For compliance, fraud prevention and safety.</b>We may use your personal information and disclose it to law enforcement, government authorities, and private parties as we believe necessary or appropriate to: (a) protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims); (b) audit our internal processes for compliance with legal and contractual requirements; (c) enforce the terms and conditions that govern the Service; and (d) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity, including cyber-attacks and identity theft.</p>
          <p><b>With your consent.</b>  In some cases we may specifically ask for your consent to collect, use or share your personal information, such as when required by law.</p>
          <p><b>HOW WE SHARE YOUR PERSONAL INFORMATION</b></p>
          <p>We may share your personal information with the following third parties and as otherwise described in this Privacy Policy or at the time of collection:</p>
          <p><b>Service providers.</b>  Companies and individuals that provide services on our behalf or help us operate the Service or our business (such as order fulfillment, shipping, payment processing, customer support, hosting, analytics, email delivery, marketing, database management services, returns processing, and risk and fraud mitigation).</p>
          <p><b>Third party platforms.</b>  Social media and other third party platforms that you connect to the Service, such as when you use options to access the Service by logging into a social media platform.  Please note, we do not control the third party’s use of your personal information. We can process data using such solutions as Analytics, Firebase Crashlytics.  As a result, some of the data is stored and processed on these third parties servers. This allows us to analyze different interactions (how often users make subscriptions, how many users chose a particular area for improvement).</p>
          <p><b>The public.</b>  Other users of the Service and the public, when you disclose personal information for public use.  For instance, you may be able review a product that you purchased, and we will display your name along with the content you submit.  We do not control how other users or third parties use any personal information that you make available to them. Please be aware that any information you post publicly can be cached, copied, screen captured, or stored elsewhere by others (e.g., search engines) before you have a chance to edit or remove it.</p>
          <p><b>Professional advisors.</b>  Professional advisors, such as lawyers, bankers, auditors, and insurers, where necessary in the course of the professional services that they render to us.</p>
          <p><b>Authorities and others.</b>  Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate to comply with law or for the compliance, fraud prevention and safety purposes described above.</p>
          <p><b>YOUR CHOICES</b></p>
          <p>In this section, we describe the rights and choices available to all users.</p>
          <p><b>Access or update your account information.</b>  If you have registered for an account with us, you may review and update certain personal information in your account profile by logging into the account.</p>          
          <p><b>Do Not Track.</b>  Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit.  We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.</p>
          <p><b>Privacy settings and location data.</b>  Users of our App can disable our access to their device’s precise geo-location in their mobile device settings.</p>
          <p><b>Choosing not to share your personal information.</b>  If you do not provide information that we need to provide the Service, we may not be able to provide you with the Service.  We will tell you what information you must provide to receive the Service when we request it which will be displayed on the Site/App/extension.</p>
          <p><b>DELETING DATA AND INFORMATION</b></p>
  <p>Users on the App and Site can request the deletion of their Account by sending us a deletion request via email to <HelpEmailLink />. We will respond to your request in a reasonable timeframe.</p>
          <p><b>OTHER SITES AND SERVICES</b></p>
          <p>The Sites may contain links to other websites and online services operated by third parties.  These links are not an endorsement of, or representation that we are affiliated with, any third party.  In addition, our content may be included on web pages or online services that are not associated with us.  We do not control third party websites or online services, and we are not responsible for their actions.  Other websites and services follow different rules regarding the collection, use, and sharing of your personal information.  We encourage you to read the privacy policies of the other websites and online services you use.</p>
          <p><b>SECURITY PRACTICES</b></p>
          <p>The security of your personal information is important to us.  We employ a number of organizational, technical, and physical safeguards designed to protect the personal information we collect.  However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information.</p>
          <p><b>INTERNATIONAL DATA TRANSFERS</b></p>
          <p>Readtronic is headquartered in the United States and may have service providers in other countries.  Your personal information may be transferred to the United States or other locations outside of your state, province, country, or other governmental jurisdiction where privacy laws may not be as protective as those in your jurisdiction.</p>
          <p><b>CCPA – For U.S.</b></p>
          <p><b>Your California privacy rights.</b>  The CCPA grants California residents the following rights.  However, these rights are not absolute, and in certain cases we may decline your request as permitted by law.</p>
          <ul> 
                <li>
                <b>Information.</b> You can request information about how we have collected, used and shared your Personal Information during the past 12 months. 
                </li>
                <ul>
                    <li>The categories of Personal Information that we have collected.</li>
                    <li>The categories of sources from which we collected Personal Information.</li>
                    <li>The business or commercial purpose for collecting and/or selling Personal Information.</li>
                    <li>The categories of third parties with whom we share Personal Information.</li>
                    <li>Whether we have disclosed your Personal Information for a business purpose, and if so, the categories of Personal Information received by each category of third party recipient.</li>
                    <li>Whether we have sold your Personal Information, and if so, the categories of Personal Information received by each category of third party recipient.</li>
                </ul>
                <li>
                <b>Access.</b> You can request a copy of the Personal Information that we have collected about you during the past 12 months. 
                </li>
                <li>
                <b>Deletion.</b> You can ask us to delete the Personal Information that we have collected from you. 
                </li>
            </ul>
          
          <p>You are entitled to exercise the rights described above free from discrimination in the form of legally prohibited increases in the price or decreases in the quality of our Service.</p>
          <p><b>How to exercise your California rights.</b>  You may exercise your California privacy rights described above as follows:</p>
          <ul> 
                <li>
                <b>Right to information, access and deletion.</b> You can request to exercise your information, access, and deletion rights by emailing <HelpEmailLink />.  We reserve the right to confirm your California residence to process your requests and will need to confirm your identity to process your requests to exercise your information, access, or deletion rights.  As part of this process, government identification may be required.  Consistent with California law, you may designate an authorized agent to make a request on your behalf. In order to designate an authorized agent to make a request on your behalf, you must provide a valid power of attorney, the requester’s valid government-issued identification, and the authorized agent’s valid government issued identification.  We cannot process your request if you do not provide us with sufficient detail to allow us to understand and respond to it. 
                </li>
                <li>
                <b>Request a list of third party marketers.</b> California’s “Shine the Light” law (California Civil Code § 1798.83) allows California residents to ask companies with whom they have formed a business relationship (primarily for personal, family or household purposes) to provide certain information about the companies’ sharing of certain personal information with third parties for their direct marketing purposes during the preceding year (if any). You can submit such a request by sending an email to <HelpEmailLink /> with “Shine the Light” in the subject line.  The request must include your current name, street address, city, state, and zip code and attest to the fact that you are a California resident. 
                </li>
            </ul>

          <p>We cannot process your request if you do not provide us with sufficient detail to allow us to understand and respond to it.</p>
          <p><b>GENERAL DATA PROTECTION REGULATION (GDPR)</b></p>
          <p>If you are from the European Economic Area (EEA), Readtronic legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
          <p>Readtronic may process your Personal Data because:</p>
          <ul> 
                <li>
                We need to perform a contract with you
                </li>
                <li>
                You have given us permission to do so
                </li>
                <li>
                The processing is in our legitimate interests and it’s not overridden by your rights
                </li>
                <li>
                For payment processing purposes
                </li>
                <li>
                To comply with the law
                </li>
            </ul>
            <p><b>Retention of Data</b></p>
          <p>Readtronic will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. </p>
          <p>
            Readtronic will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
            </p>    

            <p><b>Transfer of Data</b></p>
          <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
          <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>     
            <p>Readtronic will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

            <p><b>Disclosure of Data</b></p>
          <p>Legal Requirements</p>
          <p>Readtronic may disclose your Personal Data in the good faith belief that such action is necessary to:</p>     
          <ul> 
                <li>
                To comply with a legal obligation
                </li>
                <li>
                To protect and defend the rights or property of Readtronic
                </li>
                <li>
                To prevent or investigate possible wrongdoing in connection with the Service
                </li>
                <li>
                To protect the personal safety of users of the Service or the public
                </li>
                <li>
                To protect against legal liability
                </li>
            </ul>


            <p><b>Security of Data</b></p>
          <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

          <p><b>Your rights under the GDPR</b></p>
          <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Readtronic aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
          <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us via <HelpEmailLink /></p>
          <p>In certain circumstances, you have the following data protection rights:</p>
          <ul> 
                <li>
                <b>The right to access, update, or to delete the information we have on you.</b> Whenever made possible, you can access, update or request deletion of your Personal Data by sending us an email at <HelpEmailLink />. If you are unable to perform these actions yourself, please contact us to assist you.
                </li>
                <li>
                <b>The right of rectification.</b> You have the right to have your information rectified if that information is inaccurate or incomplete.
                </li>
                <li>
                <b>The right to object.</b>  You have the right to object to our processing of your Personal Data.
                </li>
                <li>
                <b>The right of restriction.</b>  You have the right to request that we restrict the processing of your personal information.
                </li>
                <li>
                <b>The right to data portability.</b> You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format.
                </li>
                <li>
                <b>The right to withdraw consent.</b> You also have the right to withdraw your consent at any time where Readtronic relied on your consent to process your personal information.
                </li>
            </ul>
          <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
          <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
          
          <p><b>Service Providers</b></p>
          <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
          <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
          
          <p><b>ANALYTICS</b></p>
          <p>We may use a tool called “Google Firebase Analytics” to collect information about the use of the Services. Google Firebase Analytics collects information such as how often users visit the Services, what pages they visit when they do so, and what other websites/apps they used prior to coming to the Services. Google Firebase Analytics collects only the IP address assigned to you on the date you visit the Services, rather than your name or other identifying information. We do not combine the information collected through the use of Google Firebase Analytics with personally identifiable information. We use the information we get from Google Firebase Analytics only to improve our Services. Google’s ability to use and share information collected by Google Firebase Analytics about your visits to the Services is restricted by the Google Firebase Analytics Terms of Use located at  https://firebase.google.com/terms/analytics and the Google Privacy Policy located at http://www.google.com/policies/privacy/.</p>
          <p> </p>
          
          <p><b>AdMob by Google</b></p>
          <p>AdMob by Google is provided by Google Inc.</p>
          <p>You can opt-out from AdMob by Google service by following the instructions described by Google: https://support.google.com/ads/answer/2662922?hl=en.</p>
          <p>For more information on how Google uses the collected information, please visit the “How Google uses data when you use our partners’ sites or app” page: http://www.google.com/policies/privacy/partners/ or visit the Privacy Policy of Google: http://www.google.com/policies/privacy/.</p>

          <p><b>PAYMENTS</b></p>
          <p>We may provide in-app purchase and/or paid services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
          <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
          <p>The payment processors we work with are:</p>
          <ul> 
                <li>
                <b>Apple Store In-App Payments</b> 
                </li>
                </ul>
        <p>Their Privacy Policy can be viewed at https://www.apple.com/legal/privacy/en-ww/</p>
          <ul> 
                <li>
                <b>Google Play In-App Payments</b> 
                </li>
                </ul>
        <p>Their Privacy Policy can be viewed at https://www.google.com/policies/privacy/</p>

        <p><b>Firebase Crashlytics</b></p>
          <p>We may use Firebase Crashlytics.</p>
          <p>Firebase Crashlytics provides us information about crashes and malfunctions in Applications.</p>
          <p>In case of malfunction or failure, the following information is transmitted to Firebase Crashlytics:</p>
          <ul> 
                <li>
                IP-address of the device;
                </li>
                <li>
                information about the device, such as: device ID, model and device type, name and version of the operating system, settings and language of the device;
                </li>
                <li>
                version and functionality of the application; 
                </li>
                <li>
                time of failure. 
                </li>
            </ul>
            <p>Data transmitted to Firebase Crashlytics is neither stored or transferred to other services or resources, nor associated with other data available to Google.</p>
          <p>For more information on Firebase Crashlytics privacy settings, please access:</p>
          <ul> 
                <li>
                https://firebase.google.com/terms/crashlytics; 
                </li>
                <li>
                https://firebase.google.com/terms/crashlytics-app-distribution-data-processing-terms 
                </li>
            </ul>
          
         
          <p><b>CHILDREN’S PRIVACY</b></p>
          <p>You must be 18 years of age, or the age of majority in your province, territory or country, to sign up as a registered user of the Products.</p>
          <p>Individuals under the age of 18, or the applicable age of majority, may utilize the Platform only with the involvement and consent of a parent or legal guardian, under such person’s account and otherwise subject to these Terms.</p>

          <p><b>CHANGES TO THIS PRIVACY POLICY</b></p>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          
          
          <p><b>CONTACT US</b></p>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <p><HelpEmailLink /></p>
         </Typography>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
      </Grid>
    </div>
  );
};
