import React, { useEffect } from "react"
import { useRoutes, useRedirect } from "hookrouter"
import CssBaseline from "@material-ui/core/CssBaseline"

import { Upload } from "./upload/Upload"
import { HomePage } from "./HomePage"
import { OurStory } from "./OurStory"
import { ContactUs } from "./ContactUs"
import { Sharing } from "./Sharing"
import { Login } from "./Login"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"

import { ContextProvider } from "./ContextAuth"
import { PrivacyPolicy } from "./PrivacyPolicy"
import { ExtensionSuccess } from "./ExtensionSuccess"
import { Terms } from "./Terms"
import { Pyros } from "./Pyros"
import { Playlist } from "./Playlist"
import { Follow } from "./Follow"

const routes = {
  "/home*": () => (
    <>
      <Header />
      <HomePage />
    </>
  ),
  "/upload": () => (
    <>
      <Header />
      <Upload />
    </>
  ),
  "/ourstory": () => (
    <>
      <Header />
      <OurStory />
    </>
  ),
  "/contactus": () => (
    <>
      <Header />
      <ContactUs />
    </>
  ),
  "/sharing": () => (
    <>
      <Header />
      <Sharing />
    </>
  ),
  "/privacy": () => (
    <>
      <Header />
      <PrivacyPolicy />
    </>
  ),
  "/extensionsuccess": () => (
    <>
      <Header />
      <ExtensionSuccess />
    </>
  ),
  "/terms": () => (
    <>
      <Header />
      <Terms />
    </>
  ),
  "/pyros": () => (
    <>
      <Header />
      <Pyros />
    </>
  ),
  "/login/:tab": ({ tab }) => (
    <>
      <Header />
      <Login tab={tab} />
    </>
  ),
  "/playlist": () => <Playlist />,
  "/follow": () => <Follow />,
  "/download": () =>
    window.location.replace("https://www.apple.com/ios/app-store/"),
}

const AppRouts = () => {
  useRedirect("/", "/home")
  const routeResult = useRoutes(routes)

  return routeResult ? (
    <ContextProvider>{routeResult}</ContextProvider>
  ) : (
      <h1>Not found</h1>
    )
}

const App = () => {
  useEffect(() => {
    document.title = "Readtronic"
  }, [])

  return (
    <ContextProvider>
      <CssBaseline />
      <AppRouts />
      <Footer />
    </ContextProvider>
  )
}

export default App
