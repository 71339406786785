import React from "react";
import Grid from "@material-ui/core/Grid";
import { UploadText } from "./UploadText";
import { useStyles } from "./styles";
{/*
import { Typography } from "@material-ui/core";
import { UploadFile } from "./UploadFile";
*/}

export const Upload = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer} container>
        <Grid item xs={false} sm={2}></Grid>
        <Grid item xs={12} sm={8} className={classes.wrapper}>
          {/*
              <UploadFile />
              < br />
              <Typography className={classes.orText}>Or</Typography>
              <br />
          */}
          <UploadText />
        </Grid>
        <Grid item xs={false} sm={2}></Grid>
      </Grid>
    </div>
  );
};
