export class ReadtronicClient {
    constructor(firebase) {
        this.firebase = firebase;
    }

    async uploadFeed(feed, body) {
        const authToken = await this.firebase.getCurrentUser().getIdToken();
        const options = {
            method: "POST",
            mode: 'cors',
            body: body,
            headers: {
                "FIREBASE_AUTH_TOKEN": authToken,
                "Content-Type": "application/json",
            },
        };
        return fetch(
            "https://us-central1-readtronic-b58a6.cloudfunctions.net/addArticle",
            options).then((response) => console.log("Success"));
    }

    async uploadText(feed, text) {
        console.log(`ReadtronicClient.uploadText(${feed}, ${text.length})`);
        return this.uploadFeed(feed, JSON.stringify({
            text: text,
            title: "Web Upload",
        }));
    }

    async uploadFile(feed, fileDataBase64) {
        console.log(`ReadtronicClient.uploadFile(${feed}, ${fileDataBase64.length})`);
        return this.uploadFeed(feed, JSON.stringify({ bodyBytes: fileDataBase64 }));
    }
}