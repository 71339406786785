import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Faq from "../images/faq-1.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "6%",
    marginRight: "6%",
    marginBottom: 150,
    marginTop: 160,

    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
      marginBottom: 60,
    },
  },
  gridContainer: {},
  header: {
    fontSize: 37,
    lineHeight: "1.27",
    letterSpacing: "normal",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
    },
  },
  subHeader: {
    fontSize: 15,
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "center",
    marginBottom: 74,

    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
  accordionContainer: {
    margin: "20px 0",
    padding: 13,
  },
  accHeaderExpanded: {
    fontWeight: "bold",
    color: "#4279f1",
  },
  accHeader: {
    fontWeight: "bold",
    color: "#292d34",
  },
  faqText: {
    fontSize: 15,
  },
  questionImgContainer: {
    display: 'flex',

    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
      marginBottom: 30,
    },
  },
  questionImg: {
    width: "80%",
    height: '80%',

    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "60%",
      height: '60%',
    },
  },
}));

export const FAQ = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h2">
        Frequently Asked questions
      </Typography>
      <Typography className={classes.subHeader} variant="body1">

      </Typography>

      <Grid className={classes.gridContainer} container>
        <Grid item xs={12} sm={6}>
          <div className={classes.questionImgContainer}>
            <img src={Faq} className={classes.questionImg} alt="" />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Accordion
            className={classes.accordionContainer}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={
                  expanded === "panel1"
                    ? classes.accHeaderExpanded
                    : classes.accHeader
                }
                variant="body1"
              >
                What is Readtronic?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqText} variant="body1">
                We built Readtronic to help you combat information overload.
                The <a target="_blank" href="https://apps.apple.com/app/id1494706266" rel="noopener noreferrer">Readtronic app</a> empowers you to listen to everything that has
                piled up while you’re cleaning the house, walking the dog,
                driving to work, or going for a run to help you finally feel
                caught up.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classes.accordionContainer}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={
                  expanded === "panel2"
                    ? classes.accHeaderExpanded
                    : classes.accHeader
                }
                variant="body1"
              >
                How can I add content to my Readtronic app?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqText} variant="body1">
                Once you’ve installed the <a target="_blank" href="https://apps.apple.com/app/id1494706266" rel="noopener noreferrer">Readtronic app</a>, you can add content
                any place — Safari, Chrome, news apps — you see the . You can
                also add files — docx, pdfs, and txt — via our <a href="/login/1">website</a> or
                quickly add webpages with our <a target="_blank" href="https://chrome.google.com/webstore/detail/readtronic-extension/fjoehkbckalhgimebeibhgdfjgojneml?hl=en" rel="noopener noreferrer">Chrome extension</a>. This content
                will show up in your app on your phone ready for listening on
                the go.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classes.accordionContainer}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={
                  expanded === "panel3"
                    ? classes.accHeaderExpanded
                    : classes.accHeader
                }
                variant="body1"
              >
                How good are these “premium” voices?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqText} variant="body1">
                We love them, but you don’t take our word for it. Judge for
                yourself. Just download our <a target="_blank" href="https://apps.apple.com/app/id1494706266" rel="noopener noreferrer">iOS app</a>, navigate to Settings, and click the “Voices” button. You can listen to samples of all our premium voices by pressing the play buttons on the right side of each row.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classes.accordionContainer}
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={
                  expanded === "panel5"
                    ? classes.accHeaderExpanded
                    : classes.accHeader
                }
                variant="body1"
              >
                How can I recommend a new feature?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqText} variant="body1">
                We are dedicated to constantly improving Readtonic. Please pop
                over to our <a href="/contactus">contact page</a> and tell us what you think would help
                Readtronic better server you and make your life better.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classes.accordionContainer}
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={
                  expanded === "panel6"
                    ? classes.accHeaderExpanded
                    : classes.accHeader
                }
                variant="body1"
              >
                Where can I download the Readtronic app?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.faqText} variant="body1">
                Currently, we are only supporting Apple devices. You can
                download our iOS app <a target="_blank" href="https://apps.apple.com/app/id1494706266" rel="noopener noreferrer">here</a>. We are working on Readtronic for
                Android. It’s just harder to get perfect with all the different
                Android devices out there. Sign up here if you’d like to be one
                of our beta test guinea pigs.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};
