import React from "react";
import Typography from "@material-ui/core/Typography";
import { SectionArticle } from './SectionArticle';
import { makeStyles } from "@material-ui/core/styles";
import BGMain from "./images/BGMain.svg";
import BGMobile from "./images/BGMobile.svg";

const sections = [
    {
        title: "Validating Your Vision",
        articles: [
            {
                title: "When & Why it Makes Sense to Deliver a Minimum Sellable Product, Not a Minimum Viable Product",
                link: "https://openviewpartners.com/blog/minimum-sellable-product-versus-minimum-viable-product/",
            },
            {
                title: "How Slack, Dropbox and Spotify Achieved Product-Market Fit (And How You Can Too)",
                link: "https://medium.com/swlh/slack-dropbox-spotify-product-market-fit-318838eb7de2",
            },
            {
                title: "Ditch MVPs, Adopt Minimum Viable Prototypes (MVPr)",
                link: "https://www.toptal.com/designers/prototyping/minimum-viable-prototype",
            },

        ],
    },
    {
        title: "Slicing the Pie",
        articles: [
            {
                title: "The Very First Mistake Most Startup Founders Make",
                link: "https://hbr.org/2016/02/the-very-first-mistake-most-startup-founders-make",
            },
            {
                title: "How to Split Equity Among Co-Founders",
                link: "https://hbr.org/2016/02/the-very-first-mistake-most-startup-founders-make",
            },
            {
                title: "Slicing The Pie Blog",
                link: "https://slicingpie.com/blog/",
            },

        ],
    },
    {
        title: "Defining Your Value to Your Customers",
        articles: [
            {
                title: "Have You Done A Customer Journey Map For Your Startup Yet?",
                link: "https://www.startups.com/library/expert-advice/customer-journey-map-startup",
            },
            {
                title: "Introduction to the Value Proposition Canvas",
                link: "http://wsjsociety.com/wp-content/uploads/2016/01/Intro-to-Value-Proposition-Canvas.pdf",
            },
        ],
    },
    {
        title: "Rapid Prototyping",
        articles: [
            {
                title: "20 Prototypes to Validate your New Startup Idea",
                link: "https://medium.com/swlh/20-prototype-tricks-to-validate-your-new-startup-idea-44128c623a01",
            },
            {
                title: "POC vs. MVP vs. Prototype: The Strategy Closest to Product Market Fit",
                link: "https://appinventiv.com/blog/poc-vs-mvp-prototype-the-best-strategy/",
            },


        ],
    },
    {
        title: "Explore Deep Dive: Building Something People Want",
        articles: [
            {
                title: "RESEARCH DELIVERABLES: CHAPTER #1 Research Analysis",
                link: "https://www.userinterviews.com/ux-research-field-guide-chapter/research-analysis",
            },
            {
                title: "User Experience: Synthesize Research & Develop a Design Strategy",
                link: "https://medium.com/@judytseng/user-experience-synthesize-research-develop-a-design-strategy-46571204f6a4",
            },

        ],
    },
    {
        title: "How Not to Suck at Pitching your Startup",
        articles: [
            {
                title: "Use This Framework to Master the Art of the Elevator Pitch",
                link: "https://medium.com/ideas-by-crema/use-this-framework-to-master-the-art-of-the-elevator-pitch-d86c8d1a4537",
            },
            {
                title: "Pitch Your Business",
                link: "https://www.youtube.com/watch?v=Vdx-MNA4WwU&feature=emb_logo",
            },


        ],
    },
    {
        title: "",
        articles: [
            {
                title: "",
                link: "",
            },

        ],
    },


];

const useStyles = makeStyles((theme) => ({
    avatarContainer: {
        display: "flex",
        flexDirection: "column",
    },
    root: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 122,
        backgroundImage: `url(${BGMain})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",

        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${BGMobile})`,
        },
    },
    gridContainer: {},
    textContainer: {},
    avatar: {
        margin: "118px auto 0px",
    },
    name: {
        fontFamily: "Fira Sans",
        fontSize: 17,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
    },
    date: {
        fontFamily: "Fira Sans",
        fontSize: 15,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
        textAlign: "center",
        marginBottom: 94,

        [theme.breakpoints.down("sm")]: {
            marginBottom: 24,
        },
    },
    header: {
        fontSize: 37,
        margin: "24px 7px 30px 7px",

        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
            textAlign: "center",
            margin: "24px 7px 13px 7px",
        },
    },
    text: {
        margin: "10px 7px 15px 7px",
        fontFamily: "Fira Sans",
        fontSize: 17,
        lineHeight: 1.7,
        letterSpacing: "normal",
        color: "#60656f",
    },
    mobileAvatarContainer: {
        display: "flex",
    },
    mobileNameContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "auto 17px",
    },
    mobileAvatar: {
        marginLeft: 5,
    },
    mobileName: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        lineHeight: 2,
        color: "#000",
    },
    mobileDate: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
    },
}));


export const Explore = () => {
    const classes = useStyles();
    var sectionElements = sections.map((s) =>
        <Typography className={classes.text} variant="body1" >
            <h2>{s.title}</h2>
            {s.articles.map((a) => <SectionArticle title={a.title} link={a.link} />)}
        </ Typography>);
    return (
        <>
            {sectionElements}
        </>
    );
}