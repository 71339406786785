import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import Kevin from "./images/Kevin.png";
import Boys from "./images/boys.jpg";

import BGMain from "./images/BGMain.svg";
import BGMobile from "./images/BGMobile.svg";

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 122,
    backgroundImage: `url(${BGMain})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${BGMobile})`,
    },
  },
  gridContainer: {},
  textContainer: {},
  avatar: {
    margin: "118px auto 0px",
  },
  name: {
    fontFamily: "Fira Sans",
    fontSize: 17,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
  },
  date: {
    fontFamily: "Fira Sans",
    fontSize: 15,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#b3b8b3",
    textAlign: "center",
    marginBottom: 94,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  header: {
    fontSize: 37,
    margin: "24px 7px 30px 7px",

    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      textAlign: "center",
      margin: "24px 7px 13px 7px",
    },
  },
  text: {
    margin: "10px 7px 15px 7px",
    fontFamily: "Fira Sans",
    fontSize: 17,
    lineHeight: 1.7,
    letterSpacing: "normal",
    color: "#60656f",
  },
  mobileAvatarContainer: {
    display: "flex",
  },
  mobileNameContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 17px",
  },
  mobileAvatar: {
    marginLeft: 5,
  },
  mobileName: {
    fontFamily: "Fira Sans",
    fontSize: 16,
    lineHeight: 2,
    color: "#000",
  },
  mobileDate: {
    fontFamily: "Fira Sans",
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#b3b8b3",
  },
}));

export const OurStory = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer} container>
        <Grid item xs={false} sm={1}></Grid>
        <Grid className={classes.avatarContainer} item xs={12} sm={2}>
          <Hidden xsDown>
            <img
              className={classes.avatar}
              src={Kevin}
              width="80px"
              height="80px"
              alt=""
            />
            <Typography className={classes.name} variant="body1">
              Kevin Gaffney
            </Typography>
            <Typography className={classes.date} variant="body1">
              November 24, 2020
            </Typography>
          </Hidden>
        </Grid>
        <Grid className={classes.textContainer} item xs={12} sm={6}>
          <Typography className={classes.header} variant="h2">
            Our Story
          </Typography>

          <img src={Boys} width="100%" alt="" />

          <Hidden smUp>
            <div className={classes.mobileAvatarContainer}>
              <img
                className={classes.mobileAvatar}
                src={Kevin}
                width="50px"
                height="50px"
                alt=""
              />
              <div className={classes.mobileNameContainer}>
                <Typography className={classes.mobileName} variant="body1">
                  Kevin Gaffney
                </Typography>
                <Typography className={classes.mobileDate} variant="body1">
                  November 24, 2020
                </Typography>
              </div>
            </div>
          </Hidden>

          <Typography className={classes.text} variant="body1">
            <p>One night during my post-doc in LA, I was stuck in traffic. I was staring at the sea of red brake lights in front of me thinking of all the things that I needed to read when I got home. All those articles and blogs I’d saved as tabs on my laptop and all the papers my boss had sent for our meeting in the morning.</p>
            <p>I was feeling overwhelmed and frustrated.</p>
            <p>But then it hit me, instead of listening to the radio or podcasts while I was trapped in my car, <b>“what if I could listen to audio versions of those articles, blogs, and papers.”</b> So, I called my brother, a software engineer, to see if he could make this happen. A few days later, my brother sent me a really janky, hacked together prototype…and it was amazing.</p>
            <p>I was listening on the way to work.  I was listening when I was walking my dog. I was listening while I was cleaning up around the house. <b>I had never been this on top of my reading, my dog had never been this fit, and my place this clean. I had a reading superpower.</b> From that initial prototype, we kept sharing it with friends and colleagues and improving it based on their invaluable feedback. (Thank you!).</p>
            <p>Now we are excited to share it with you.  Please download our iOS app and Chrome extension and let us know what you think!</p>
            <p><u><b>Pro Tip 1</b></u>: </p>
            <p>There are three ways to add content to your Readtronic app:</p>
            <ol><li>directly from your phone by <a href="/sharing">enabling sharing</a></li>
              <li>via our <a href="/login/1">website</a></li>
              <li>with our <a href="https://chrome.google.com/webstore/detail/readtronic-extension/fjoehkbckalhgimebeibhgdfjgojneml?hl=en">Chrome extension</a></li>
            </ol>

          </Typography>

        </Grid>
        <Grid item xs={12} sm={1}></Grid>
      </Grid>
    </div>
  );
};
