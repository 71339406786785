import React from "react";
import Typography from "@material-ui/core/Typography";
import { SectionArticle } from './SectionArticle';
import { makeStyles } from "@material-ui/core/styles";
import BGMain from "./images/BGMain.svg";
import BGMobile from "./images/BGMobile.svg";

const sections = [
    {
        title: "OKR: Objectives &amp Key Results",
        articles: [
            {
                title: "Measure what matters: summary",
                link: "https://www.davidtuite.com/measure-what-matters-notes/#:~:text=Measure%20What%20Matters%20by%20John,book%20is%20about%20OKRs%20themselves",
            },
            {
                title: "How to Set Good OKRs With Examples",
                link: "https://www.youtube.com/watch?v=qYAnz1PwpRA",
            },
            {
                title: "Why the secret to success is setting the right goals | John Doerr",
                link: "https://www.youtube.com/watch?v=L4N1q4RNi9I&t=69s",
            },

        ],
    },
    {
        title: "Fundraising: Who, What, When, Why, and How",
        articles: [
            {
                title: "Decide which type of investor to target for raising capital",
                link: "https://techcrunch.com/2019/11/14/decide-which-type-of-investor-to-target-for-raising-capital/",
            },
            {
                title: "What I wish I knew about fundraising as a first- time founder",
                link: "https://www.preethikasireddy.com/post/what-i-wish-i-knew-about-fundraising-as-a-first-time-founder",
            },
        ],
    },
    {
        title: "Fundraising Chalktalk",
        articles: [
            {
                title: "CALCULATING SHARE PRICE WITH OUTSTANDING CONVERTIBLE NOTES OR SAFES",
                link: "https://www.cooleygo.com/calculating-share-price-outstanding-convertible-notes-or-safes/",
            },
            {
                title: "A Complete Beginner’s Guide to VC Term Sheets",
                link: "https://www.fundingnote.com/blog/vc-term-sheets-guide-297",
            },
            {
                title: "NEGOTIATING TERM SHEETS: FOCUS ON WHAT’S IMPORTANT",
                link: "https://www.cooleygo.com/negotiating-term-sheets/",
            },
            {
                title: "A Founder’s Guide to Making a Section 83(b) Election",
                link: "https://www.theventurealley.com/2016/09/a-founders-guide-83b-election/",
            },
        ],
    },
    {
        title: "Nailing Product/Market Fit: A Definitive Guide",
        articles: [
            {
                title: "Product-Market Fit: Finding the Best Customers for your Product",
                link: "https://www.profitwell.com/recur/all/what-is-product-market-fit",
            },
            {
                title: "How Slack, Dropbox and Spotify Achieved Product-Market Fit (And How You Can Too)",
                link: "https://medium.com/swlh/slack-dropbox-spotify-product-market-fit-318838eb7de2",
            },
            {
                title: "When has a consumer startup hit product/market fit?",
                link: "https://andrewchen.co/when-has-a-consumer-startup-hit-productmarket-fit/",
            },
            {
                title: "The Revenge of the Fat Guy",
                link: "https://blog.pmarca.com/2010/03/20/the-revenge-of-the-fat-guy/",
            },
        ],
    },
    {
        title: "Why Mastering Onboarding & Retention Matters Most",
        articles: [
            {
                title: "Casey Accidental",
                link: "https://caseyaccidental.com/",
            },
        ],
    },
    {
        title: "Entrepreneurial Selling",
        articles: [
            {
                title: "Sell Like An Entrepreneur: The Simple Mindset Shift That Wins More Deals",
                link: "https://www.saleshacker.com/entrepreneurial-selling/",
            },
            {
                title: "",
                link: "",
            },
            {
                title: "",
                link: "",
            },

        ],
    },
];

const useStyles = makeStyles((theme) => ({
    avatarContainer: {
        display: "flex",
        flexDirection: "column",
    },
    root: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 122,
        backgroundImage: `url(${BGMain})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",

        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${BGMobile})`,
        },
    },
    gridContainer: {},
    textContainer: {},
    avatar: {
        margin: "118px auto 0px",
    },
    name: {
        fontFamily: "Fira Sans",
        fontSize: 17,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
    },
    date: {
        fontFamily: "Fira Sans",
        fontSize: 15,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
        textAlign: "center",
        marginBottom: 94,

        [theme.breakpoints.down("sm")]: {
            marginBottom: 24,
        },
    },
    header: {
        fontSize: 37,
        margin: "24px 7px 30px 7px",

        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
            textAlign: "center",
            margin: "24px 7px 13px 7px",
        },
    },
    text: {
        margin: "10px 7px 15px 7px",
        fontFamily: "Fira Sans",
        fontSize: 17,
        lineHeight: 1.7,
        letterSpacing: "normal",
        color: "#60656f",
    },
    mobileAvatarContainer: {
        display: "flex",
    },
    mobileNameContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "auto 17px",
    },
    mobileAvatar: {
        marginLeft: 5,
    },
    mobileName: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        lineHeight: 2,
        color: "#000",
    },
    mobileDate: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
    },
}));


export const Launch = () => {
    const classes = useStyles();
    var sectionElements = sections.map((s) =>
        <Typography className={classes.text} variant="body1" >
            <h2>{s.title}</h2>
            {s.articles.map((a) => <SectionArticle title={a.title} link={a.link} />)}
        </ Typography>);
    return (
        <>
            {sectionElements}
        </>
    );
}