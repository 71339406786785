import React from "react";

export const SectionArticle = (props) => {
    return (
        <div>
            {/* 
            <p>{props.title} <br></br><a target="_blank"
                rel="noopener noreferrer"
                href={props.link}>{props.link}</a>
            </p>
            */}
            <p><a target="_blank"
                rel="noopener noreferrer"
                href={props.link}>{props.title}</a>
            </p>
        </div>
    );
}