import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import Robot from "./images/Circle-logo.svg";

import BGMain from "./images/BGMain.svg";
import BGMobile from "./images/BGMobile.svg";
import { HelpEmailLink } from "./HelpEmailLink";

const useStyles = makeStyles((theme) => ({
    avatarContainer: {
        display: "flex",
        flexDirection: "column",
    },
    root: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 122,
        backgroundImage: `url(${BGMain})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",

        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${BGMobile})`,
        },
    },
    gridContainer: {},
    textContainer: {},
    avatar: {
        margin: "118px auto 0px",
    },
    name: {
        fontFamily: "Fira Sans",
        fontSize: 17,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
    },
    date: {
        fontFamily: "Fira Sans",
        fontSize: 15,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
        textAlign: "center",
        marginBottom: 94,

        [theme.breakpoints.down("sm")]: {
            marginBottom: 24,
        },
    },
    header: {
        fontSize: 37,
        margin: "24px 7px 30px 7px",

        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
            textAlign: "center",
            margin: "24px 7px 13px 7px",
        },
    },
    text: {
        margin: "10px 7px 15px 7px",
        fontFamily: "Fira Sans",
        fontSize: 17,
        lineHeight: 1.7,
        letterSpacing: "normal",
        color: "#60656f",
    },
    mobileAvatarContainer: {
        display: "flex",
    },
    mobileNameContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "auto 17px",
    },
    mobileAvatar: {
        marginLeft: 5,
    },
    mobileName: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        lineHeight: 2,
        color: "#000",
    },
    mobileDate: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
    },
}));

export const Terms = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid className={classes.gridContainer} container>
                <Grid item xs={false} sm={1}></Grid>
                <Grid className={classes.avatarContainer} item xs={12} sm={2}>
                    <Hidden xsDown>
                        <img
                            className={classes.avatar}
                            src={Robot}
                            width="80px"
                            height="80px"
                            alt=""
                        />
                        <Typography className={classes.name} variant="body1">
                            Last Updated
            </Typography>
                        <Typography className={classes.date} variant="body1">
                            November 23, 2020
            </Typography>
                    </Hidden>
                </Grid>
                <Grid className={classes.textContainer} item xs={12} sm={6}>
                    <Typography className={classes.header} variant="h2">
                        Terms &amp; Conditions - Readtronic
          </Typography>

                    <Hidden smUp>
                        <div className={classes.mobileAvatarContainer}>
                            <img
                                className={classes.mobileAvatar}
                                src={Robot}
                                width="50px"
                                height="50px"
                                alt=""
                            />
                            <div className={classes.mobileNameContainer}>
                                <Typography className={classes.mobileName} variant="body1">
                                    Last Updated
                </Typography>
                                <Typography className={classes.mobileDate} variant="body1">
                                    November 23, 2020
                </Typography>
                            </div>
                        </div>
                    </Hidden>

                    <Typography className={classes.text} variant="body1">
                        <ol>
                            <li><b>Introduction</b></li>
                            <p>The Readtronic website located on www.readtronic.com (“Site”), the Readtronic Mobile Applications (“App”) available on Apple App Store (IOS) and Google Play Store (Android), and the Readtronic browser extension (collectively “platform”), are owned and operated by Readtronic LLC from the United States. Throughout the Site, the terms “we”, “us”, “Readtronic”, and “our” refer to Readtronic LLC. We offer the Readtronic platform, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>

                            <p>By using any feature of our platform, you (“User”) engage in our “Services” and agree to be bound by the following terms and conditions (“Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms and Conditions apply to all users of the Site and App including without limitation users who are browsers, customers, users, and/ or contributors of content.</p>

                            <p><b>PLEASE READ THE FOLLOWING TERMS AND DISCLAIMERS CAREFULLY BEFORE USING THE SERVICES.</b></p>
                            <p>Please read these Terms carefully before using our services. When using our services, you accept and agree to these Terms which bind you legally. If you do not agree with these Terms, our Privacy Policy, or any other of our policy, you should not use the Services.</p>

                            <li><b>General Information</b></li>
                            <p><b>•	What we do.</b> Readtronic offers a platform for general users to convert some text files and text content to audio files. For more information on the services we offer, please find more details on our Site or App.</p>
                            <p><b>•	Sole discretion.</b>  We reserve the right to add and/or discontinue any product or service anytime at our sole discretion and without notice. We also reserve the right to take appropriate steps to protect Readtronic and/or its Members/Users from any abuse/misuse as it deems appropriate in its sole discretion.</p>

                            <li><b>App Updates and Functionality</b></li>
                            <p>From time to time, updates to the App may be made available through the App Store and Play Store. Depending on the update, and whether you utilized the App Store or Play Store to download and access the App, you may not be able to use the App until you have installed the latest version. In addition, you acknowledge that the App is provided over the Internet and mobile networks so the quality and availability of the App may be affected by factors outside our reasonable control. Accordingly, we do not accept any responsibility for any connectivity issues that you may experience when using the App. In addition, you acknowledge and agree that you (and not us) are responsible for obtaining and maintaining all telecommunications, including cellular data, download charges, broadband and computer hardware, equipment and services needed to access and use the platform, and for paying any data charges that may be charged to you by your network provider in connection with your use of our platform.</p>

                            <li><b>App Versions</b></li>
                            <p>Access and use of the Readtronic App is free. The App offers in-app purchases/paid subscriptions which offer additional features. Users get free access to upload from thier phone, on through our website, or our browser extension. Users using our free service will see Ads on the platform. Users who pay for subscriptions can avail an ad-free experienced and also get additional features including premium voices.</p>

                            <li><b>Subscriptions</b></li>
                            <p>•	Our services and subscriptions are not transferable and therefore cannot be sold or exchanged or transferred in any way whatsoever. A subscription fee-based program, which gives access to all features including and beyond the Free service. You can become a subscriber by purchasing a subscription from our Apps, where allowed by the App marketplace partners, or through a bundle with one or more of our bundle subscription partners. Please note that if you purchase a subscription through the Apple iTunes Store or our iPhone application, the sale is final, and we will not provide a refund. Your purchase will be subject to Apple’s applicable payment policy, which also may not provide for refunds. If you purchase a subscription through the Google Play store, the sale is final and we will not provide a refund. Your purchase will be subject to Google’s applicable payment policy, which also may not provide for refunds. If you purchase through one or more of our bundle subscription partners, the purchase may be further subject to the Terms and Conditions of such partners, and payment and management of the bundle subscription may be administered by them.</p>
                            <p>•	You acknowledge and agree that Readtronic is authorized to charge the Payment Method used for (i) the initial monthly subscription fee at the rate secured at the time of purchase, and (ii) the renewal subscription fee(s) at the effective rate in effect at the time of any such renewal. You must cancel your subscription before it renews in order to avoid billing of the renewal subscription fee to the Payment Method you provided. Refunds cannot be claimed for any partial subscription period.</p>
                            <p>•	Our obligation to provide the Products only comes into being when we take receipt of your Order, Apple/Google shall confirm your purchase to you by email. You agree not to hold us responsible for banking charges incurred due to payments on your account. If payment is not received by us from the Payment Method you provided, you agree to pay all amounts due upon demand by us. You agree that you are not permitted to resell any Products purchased through Readtronic for commercial purposes.</p>
                            <p>•	We may at any time and from time to time, in our sole discretion, change the fees and charges, or add new fees and charges, in relation to any of the Services. We will notify you at least 30 calendar days in advance of any such change. If you do not agree to the change, you may cancel your membership or subscription in your account settings or by emailing <HelpEmailLink />.</p>

                            <li><b>Permitted use </b></li>
                            <p>You agree to use our platform only for purposes that are permitted by these Terms and Conditions and in compliance with all applicable laws, regulations, and generally accepted practices or guidelines in the relevant jurisdictions. You may only use the Services for your non-commercial, non-exclusive, non-assignable, non-transferable, and limited personal use, and no other purposes.</p>
                            <p>You will not (and will not attempt to):</p>
                            <p>a.	Use the Services for any illegal or unlawful purpose;</p>
                            <p>b.	Access any of the Services by any means other than through the interface that is provided by Readtronic;</p>
                            <p>c.	Gain unauthorized access to Readtronic’s computer system or engage in any activity that interferes with the performance of, or impairs the functionality or security of the Services, Readtronic’s networks, and computer systems;</p>
                            <p>d.	Access any of the Services through any automated means or with any automated features or devices (including use of scripts or web crawlers);</p>
                            <p>e.	Access or collect any personally identifiable information, including any names, email addresses, or other such information for any purpose, including, without limitation, commercial purposes;</p>
                            <p>f.	Reproduce, duplicate, copy, sell, trade, or resell any aspect of the Services for any purpose; and</p>
                            <p>g.	Reproduce, duplicate, copy, sell, trade, or resell any products or services bearing any trademark, service mark, trade name, logo, or service mark owned by Readtronic in a way that is likely or intended to confuse the owner or authorized user of such marks, names or logos.</p>

                            <li><b>7.	Accounts, Registrations, and Passwords</b></li>
                            <p>You are solely responsible for maintaining the confidentiality of your account and for restricting access to your computer and mobile/tablet.  If you open an account, register, or otherwise provide us with any information, you agree to provide us with current, complete, and accurate information as requested by any forms. Readtronic is not responsible for any errors or delays in responding to any inquiry or request caused by any incorrect, outdated, or incorrect information provided by you or any technical problems beyond the control of Readtronic. You may not disclose the Password to another person or entity or permit another entity to access the Site and Apps using such a Password. You must notify Readtronic immediately of any breach of security or unauthorized use of your account. Readtronic cannot be responsible and disclaims all liability in connection with, the use of any information that you post or display on the Site and App. Multiple profiles of the same person are not allowed on the Site and App.</p>

                            <li><b>8.	User Content</b></li>
                            <p>User can upload any text file/document on the platform and they are responsible for any content/file they upload on Readtronic.</p>

                            <li><b>9.	Intellectual Property Rights</b></li>
                            <p>Your use of the platform grants no rights to you concerning any copyright, designs, trademarks, and all other intellectual property and material rights mentioned, displayed, or relating to the Content (defined below) on the Site and App.  All Content, including third party trademarks, designs, and related intellectual property rights mentioned or displayed on this Site and the App, are protected by national intellectual property and other laws. Any unauthorized reproduction, redistribution, or other use of the Content is prohibited and may result in civil and criminal penalties. You may use the Content only with our prior written and express authorization. To inquire about obtaining authorization to use the Content, please contact us at <HelpEmailLink />.</p>
                            <p>In addition to the intellectual property rights mentioned above, "Content" is defined as any graphics, photographs, including all image rights, sounds, music, video, audio, or text on the Site and App.</p>

                            <li><b>Digital Millennium Copyright Act</b></li>
                            <p>Readtronic expects all users to respect the intellectual property rights of others. Readtronic may remove material that appears in its sole discretion to infringe upon the intellectual property rights of others and we will terminate the access rights of any repeat infringer. If you are a copyright owner or an agent thereof and believe that any Content infringes upon your copyrights, you may notify us at <HelpEmailLink />.  The notification must include the following information: physical or electronic signature of the owner or authorized agent of the owner of the allegedly infringed work; identification of the allegedly infringed work; identification of the material that is claimed to be infringing and reasonably sufficient information for Readtronic to locate the material; contact information of the notifying party, such as an address, telephone number, and email; a statement that the notifying party has a good faith belief that the use of the material in the manner complained of is not authorized by the owner of the allegedly infringed work, its agent or the law; and a statement, under penalty of perjury that the information in the notification is accurate and the notifying party is the owner or authorized agent of the allegedly infringed work.</p>

                            <li><b>Monitoring Activity</b></li>
                            <p>Readtronic has no obligation to monitor the platform or any portion thereof. However, we reserve the right to review any posted content and remove, delete, redact or otherwise modify such content, in our sole discretion, at any time and from time to time, without notice or further obligation to you. Readtronic has no obligation to display or post any content. Readtronic, subject to the Privacy Policy reserves the right to disclose, at any time and from time to time, any information or posted the content that it deems necessary or appropriate, including without limitation to satisfy any applicable, law, regulation, contractual obligation, legal, dispute process, or governmental request. Readtronic shall have no liability in connection with any content or content submitted to, transmitted via, or displayed or posted on the platform, regardless of whether provided by Readtronic or any other party.</p>

                            <li><b>Disclaimer</b></li>
                            <p>TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, READTRONIC EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING ANY (A) WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE AS TO THE PLATFORM AND ITS CONTENT, INCLUDING THE INFORMATION, DATA, SOFTWARE, OR PRODUCTS CONTAINED THEREIN, OR THE RESULTS OBTAINED BY THEIR USE OR AS TO THE PERFORMANCE THEREOF, (B) WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING, AND (C) WARRANTIES OR CONDITIONS OF UNINTERRUPTED OR ERROR-FREE ACCESS OR USE. THE PLATFORM AND ALL CONTENTS THEREIN AND COMPONENTS THEREOF ARE PROVIDED ON AN “AS IS” BASIS AND YOUR USE OF THE PLATFORM IS AT YOUR OWN RISK.</p>

                            <li><b>Limitation of Liability</b></li>
                            <p>You agree that in no event shall Readtronic be liable to you, or any third party, for any lost profits, incidental, consequential, punitive, special, or indirect damages arising out of or in connection with the platform or the Terms and Conditions, even if advised as to the possibility of such damages, regardless of whether the claim for such damages is based in contract, tort, strict liability, or otherwise. This limitation on liability includes, but is not limited to, any (i) errors, mistakes, or inaccuracies in any Content or for any loss or damage of any kind incurred by you as a result of your use of or reliance on the Content; (ii) the transmission of any bugs, viruses, Trojan horses or the like which may infect your equipment and cause the failure of mechanical or electronic equipment; (iii) unauthorized access to or use of the platform secure servers and/or any personal information and/or financial information stored therein; or (iv) theft, operator errors, strikes, or other labor problems or any force majeure. You agree that you use the platform/or any Third Party Sites at your own risk. You further understand and agree that we are not responsible or liable for your illegal, unauthorized, or improper use of information transmitted, monitored, stored, or received using the platform.</p>

                            <li><b>End User License Agreement (EULA)</b></li>
                            <p>•	ReadSpeaker LLC has granted us, a limited, revocable, nonexclusive, non-transferable license to use the Premium Voices in object code only, on a single computer owned, leased, or otherwise controlled by you, and to use the Software only as authorized under this Agreement. Transfer to another computer, concurrent use on two or more computers is not permitted without ReadSpeaker LLC ‘s written permission and the payment of additional fees. You agree that you will not assign, sublicense, transfer, pledge, lease, rent, or share your rights under this Agreement. In no event may you distribute the software. You agree that you will not engineer, reverse assemble, disassemble or decompile the Software, or otherwise attempt to discover any source code, algorithms, trade secrets or other proprietary rights embedded in or relating to the Software by any means whatsoever.</p>
                            <p>•	Your payment of applicable subscription fees, Readtronic grants you a limited, non-exclusive, revocable license to listen premium voice granted to us by ReadSpeaker LLC and make personal non-commercial use of the Products.</p>
                            <p>•	The Products contain or embody copyrighted material, proprietary material, or other intellectual property of Readtronic or its licensors. All right, title, and ownership in the Products remain with Readtronic or its licensors, as applicable. The rights to download and use the Products are licensed to you and are not being sold to you, and you have no rights in them other than to use them in accordance with this License Agreement and our other Terms.</p>
                            <p>•	You may not make the Products available to the public. The Products made available (in whole or in part) are owned by Readtronic or its licensors and your use of them must be in accordance with these Terms.</p>

                            <li><b>Indemnification</b></li>
                            <p>You agree to indemnify and hold Readtronic and its subsidiaries, affiliates, officers, directors, agents, and employees, harmless from and against any suit, action, claim, demand, penalty, or loss, including reasonable attorneys’ fees, made by or resulting from any third party due to or arising out of your use of our Services, breach of the Terms and Conditions or the materials it incorporates by reference, or your violation of any law, regulation, order or other legal mandates, or the rights of a third party.</p>

                            <li><b>Dispute Resolution &amp; Governing Laws</b></li>
                            <p>In the event of a dispute arising out of or in connection with these terms and conditions or any contract between you and us, then you agree to attempt to settle the dispute by engaging in good faith with us in a process of mediation before commencing arbitration or litigation.</p>
                            <p>These Terms and Conditions shall be governed by and construed in accordance with the law of the State of Delaware, United States and you hereby submit to the exclusive jurisdiction of the Delaware courts.</p>

                            <li><b>Children</b></li>
                            <p>You must be 18 years of age, or the age of majority in your province, territory or country, to sign up as a registered user of the Products.</p>
                            <p>Individuals under the age of 18, or the applicable age of majority, may utilize the Platform only with the involvement and consent of a parent or legal guardian, under such person’s account and otherwise subject to these Terms.</p>

                            <li><b>Privacy</b></li>
                            <p>For more information on how we collect your information and cookies, please refer to our Privacy Policy.</p>

                            <li><b>Deleting Data And Information</b></li>
                            <p>Users on the App and Site can request the deletion of their Account, data, and information by sending us a deletion request via email to <HelpEmailLink />. We will respond to your request in a reasonable timeframe. For more information on this section, kindly refer to our privacy policy.</p>

                            <li><b>Changes</b></li>
                            <p>We reserve the right to update and revise these Terms and Conditions at any time. You will know if these Terms and Conditions have been revised since your last visit to the platform by referring to the "Effective Date of Current Policy" date at the top of this page. Your use of our platform constitutes your acceptance of these Terms and Conditions as amended or revised by us from time to time, and you should, therefore, review these Terms and Conditions regularly.</p>

                            <li><b>Electronic Communications</b></li>
                            <p>When you visit the platform, or send us e-mails, you are communicating with us electronically. In so doing, you consent to receive communications from us electronically. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communication is in writing.</p>

                            <li><b>Severability</b></li>
                            <p>If any of these Terms and Conditions shall be deemed invalid, void, or for any reason unenforceable, that term shall be deemed severable and shall not affect the validity and enforceability of any remaining terms or conditions.</p>

                            <li><b>Assignment</b></li>
                            <p>We shall be permitted to assign, transfer, or subcontract our rights and obligations under these terms without your consent or any notice to you. You shall not be permitted to assign, transfer, or subcontract any of your rights and obligations under this agreement.</p>

                            <li><b>Force Majeure</b></li>
                            <p>Readtronic is not liable for any delays caused by circumstances beyond Readtronic’s control, e.g. general labor dispute, extreme weather, acts of war, fire, lightning, terrorist attacks, changed governmental orders, technical problems, defects in any system or other communication and defects or delays in the service by sub-suppliers due to circumstances set forth above. Such circumstances shall result in relief from damages and other measures. If any such situation should arise, Readtronic shall inform the Customer accordingly both at the beginning and the end of the period for the current situation. If the situation has lasted for more than two months, both the Customer and Readtronic are entitled to terminate the purchase with immediate effect.</p>

                            <li><b>Entire Agreement</b></li>
                            <p>These Terms and Conditions set forth the entire understanding and agreement between you and Readtronic concerning the subject matter herein and supers all prior or contemporaneous communications and proposals, whether electronic, oral, or written concerning the platform. A printed version of these Terms and Conditions and any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Any rights not expressly granted herein are reserved. You may not assign the Terms and Conditions, or assign, transfer or sublicense your rights therein. A failure to act concerning a breach by you or others does not waive Readtronic's right to act concerning subsequent or similar breaches.</p>

                            <li><b>Relationship</b></li>
                            <p>No agency, partnership, joint venture, or employment relationship is created as a result of these Terms and neither of us has any authority of any kind to bind the other in any respect.</p>

                            <li><b>Violation and Termination</b></li>
                            <p>Any conduct that in any way violates these Terms or any posted restrictions or guidelines may result, in our sole discretion, in the termination of your license and right to utilize the Services to access Content or for any other purpose, and/or our pursuit of any legal damages or remedies. If necessary, or as authorized under applicable law, we may cooperate with local, state and/or federal authorities to protect the platform, the Services, the Content, Readtronic, its Affiliates, Licensors, members, employees, agents and/or visitors; to comply with applicable laws; or to prevent unauthorized access or use of the Services or the Content. We retain the right to deny access to the Services, in our sole discretion, to any visitor/user for any reason, including for any violation of these Terms.</p>

                            <li><b>Contact Us</b></li>
                            <p>For any questions, complaints, and queries or to report any violations, kindly get in touch:</p>
                            <p>READTRONIC LLC</p>
                            <p>Email: <HelpEmailLink /></p>
                        </ol>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
            </Grid>
        </div>
    );
};
