import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

import { FollowCard } from "./components/FollowCard"

import BGMain from "./images/BGMain.svg"
import BGMobile from "./images/BGMobile.svg"

const useStyles = makeStyles(theme => ({
    root: {
        paddingRight: 15,
        paddingBottom: 122,
        backgroundImage: `url(${BGMain})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",

        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${BGMobile})`,
        },
    },
    gridContainer: {},
    gridCards: {
        display: "flex",
        flexDirection: "column",
        marginTop: 15,
    },
    text: {
        fontFamily: "Fira Sans",
        fontSize: 17,
        lineHeight: 1.7,
        letterSpacing: "normal",
        color: "#60656f",
    },
    cardImg: {
        margin: "0 auto",
        hight: 60,
        width: 60,
        borderRadius: 3,
        backgroundColor: 'white',
    },
    avatarImg: {
        marginRight: 8,
        hight: 25,
        width: 25,
        borderRadius: 25,
    },
    gridButtons: {
        display: "flex",
        marginTop: 5,
    },
    button: {
        fontFamily: "Lato",
        borderRadius: 0,
        padding: 0,
        backgroundColor: "rgba(96, 101, 111, 0.06)",

        "& .MuiButton-label": {
            fontSize: 19,
        },
    },
    buttonActive: {
        fontFamily: "Lato",
        borderRadius: 0,
        padding: 0,
        fontWeight: "bold",
        borderBottom: "solid 2px #292929",

        "& .MuiButton-label": {
            fontSize: 19,
        },
    },
}))

export const Follow = () => {
    const [screen, setScreen] = useState("NEWS")
    const classes = useStyles()

    const NEWS_DATA = [
        {
            id: 1,
            image: <img className={classes.cardImg} src="https://pbs.twimg.com/profile_images/3476892537/fb4893a1aeba7720af94314e5568c71d_400x400.png" alt="" />,
            header: "Letters from an American",
            bodyText:
                "I’m a professor of American history. This is a chronicle of today’s political landscape, but because you can’t get a grip on today’s politics without an outline of America’s Constitution, and laws, and the economy, and social customs, this newsletter explores what it means, and what it has meant, to be an American.",
            author: "Heather Cox Richardson",
            avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/3476892537/fb4893a1aeba7720af94314e5568c71d_400x400.png" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://heathercoxrichardson.substack.com/feed/",
            linkHeader: "https://readtronic.com/follow?url=https://heathercoxrichardson.substack.com/feed/",
            linkPlus: "https://readtronic.com/follow?url=https://heathercoxrichardson.substack.com/feed/",
            linkAuthor: "https://heathercoxrichardson.substack.com",
        },
        {
            id: 2,
            image: <img className={classes.cardImg} src="https://nextdraft.com/wp-content/themes/nextdraft/images/head-large@2x.png" alt="" />,
            header: "NextDraft",
            bodyText:
                "The Day's Most Fascinating News from Dave Pell",
            author: "Dave Pell",
            avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/1298463299407769600/zhXOS0cd_400x400.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=http://nextdraft.com/archives/feed/",
            linkHeader: "https://readtronic.com/follow?url=http://nextdraft.com/archives/feed/",
            linkPlus: "https://readtronic.com/follow?url=http://nextdraft.com/archives/feed/",
            linkAuthor: "https://nextdraft.com",
        },
        /*{
            id: 3,
            image: <img className={classes.cardImg} src="" alt="" />,
            header: "",
            bodyText:
                "",
            author: "",
            avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
            date: "",
            linkImg: "",
            linkHeader: "",
            linkPlus: "",
            linkAuthor: "",
        },*/
    ]
    const TRAVEL_DATA = [
        {
            id: 1,
            image: <img className={classes.cardImg} src="https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/29511746_10156094508025429_1443337452176539648_n.png?_nc_cat=1&ccb=3&_nc_sid=09cbfe&_nc_ohc=NOTfWi4w0q8AX9Frjsa&_nc_ht=scontent-ort2-1.xx&oh=612f5fcd833b6151b067ff6d76a1a658&oe=60657498" alt="" />,
            header: "Outside Magazine",
            bodyText:
                "Since 1977, Outside has been about one simple idea: to inspire active participation in the world outside. With in-depth narrative storytelling, profiles, videos, and authoritative service, we explore the intersection between our lives and the natural world.",
            author: "Outside Magazine",
            avatar: <img className={classes.avatarImg} src="https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/29511746_10156094508025429_1443337452176539648_n.png?_nc_cat=1&ccb=3&_nc_sid=09cbfe&_nc_ohc=NOTfWi4w0q8AX9Frjsa&_nc_ht=scontent-ort2-1.xx&oh=612f5fcd833b6151b067ff6d76a1a658&oe=60657498" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://www.outsideonline.com/rss/all/rss.xml",
            linkHeader: "https://readtronic.com/follow?url=https://www.outsideonline.com/rss/all/rss.xml",
            linkPlus: "https://readtronic.com/follow?url=https://www.outsideonline.com/rss/all/rss.xml",
            linkAuthor: "https://www.outsideonline.com/contribute",
        },
        {
            id: 2,
            image: <img className={classes.cardImg} src="https://dx0dyd9jru7i3.cloudfront.net/99607_01_Black_100217_1000x1000.jpg" alt="" />,
            header: "Duct Tape Diaries",
            bodyText:
                "Friendly, insightful and inspiring, Duct Tape Diaries celebrates the can-do, will-do spirit of NRS and our friends in the boating community.",
            author: "NRS.com",
            avatar: <img className={classes.avatarImg} src="https://www.nrs.com/assets/images/social-952x498.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://community.nrs.com/duct-tape/feed/",
            linkHeader: "https://readtronic.com/follow?url=https://community.nrs.com/duct-tape/feed/",
            linkPlus: "https://readtronic.com/follow?url=https://community.nrs.com/duct-tape/feed/",
            linkAuthor: "https://community.nrs.com/duct-tape/",
        },
        {
            id: 3,
            image: <img className={classes.cardImg} src="https://64.media.tumblr.com/tumblr_levbzz2ctt1qfuznso1_500.jpg" alt="" />,
            header: "Anthony Bourdain",
            bodyText:
                "A continuous dribble of stuff we're thinking about and think you should know about.",
            author: "Anthony Bourdain",
            avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/855122963674390529/0AMZgedO_400x400.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://anthonybourdain.tumblr.com/rss",
            linkHeader: "https://readtronic.com/follow?url=https://anthonybourdain.tumblr.com/rss",
            linkPlus: "https://readtronic.com/follow?url=https://anthonybourdain.tumblr.com/rss",
            linkAuthor: "https://anthonybourdain.tumblr.com/",
        },
    ]
    const FOOD_DATA = [
        {
            id: 1,
            image: <img className={classes.cardImg} src="https://64.media.tumblr.com/tumblr_levbzz2ctt1qfuznso1_500.jpg" alt="" />,
            header: "Anthony Bourdain",
            bodyText:
                "A continuous dribble of stuff we're thinking about and think you should know about.",
            author: "Anthony Bourdain",
            avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/855122963674390529/0AMZgedO_400x400.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://anthonybourdain.tumblr.com/rss",
            linkHeader: "https://readtronic.com/follow?url=https://anthonybourdain.tumblr.com/rss",
            linkPlus: "https://readtronic.com/follow?url=https://anthonybourdain.tumblr.com/rss",
            linkAuthor: "https://anthonybourdain.tumblr.com/",
        },
        {
            id: 2,
            image: <img className={classes.cardImg} src="https://static1.squarespace.com/static/5b9eb9e35cfd7967a7b5b659/t/5b9ee5fb562fa7fce8ae54bd/1605286360186/?format=1500w" alt="" />,
            header: "Whiskey & Soba",
            bodyText:
                "I eat, travel, and take photos.",
            author: "Whiskey & Soba",
            avatar: <img className={classes.avatarImg} src="https://images.squarespace-cdn.com/content/v1/5b9eb9e35cfd7967a7b5b659/1537212946159-F13PIF5C6EU7DK9YWUFE/ke17ZwdGBToddI8pDm48kAob4oFDCylE9noX3TKqBxtZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpyN51cvbdTmAkAomzhe6rlfk_96am3hwUz8aF9U6bzFRhN3LU7jr2sWsDb_b6WXWZ0/1500345426131.jpeg?format=500w" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://www.whiskeyandsoba.com/ws-blog?format=rss",
            linkHeader: "https://readtronic.com/follow?url=https://www.whiskeyandsoba.com/ws-blog?format=rss",
            linkPlus: "https://readtronic.com/follow?url=https://www.whiskeyandsoba.com/ws-blog?format=rss",
            linkAuthor: "https://www.whiskeyandsoba.com/",
        },
    ]
    const SPORTS_DATA = [
        {
            id: 1,
            image: <img className={classes.cardImg} src="https://pbs.twimg.com/profile_images/859038845521846272/X11uETcv_400x400.jpg" alt="" />,
            header: "Viva El Birdos",
            bodyText:
                "A St. Louis Cardinals community",
            author: "Viva El Birdos",
            avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/859038845521846272/X11uETcv_400x400.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://www.vivaelbirdos.com/rss/current.xml",
            linkHeader: "https://readtronic.com/follow?url=https://www.vivaelbirdos.com/rss/current.xml",
            linkPlus: "https://readtronic.com/follow?url=https://www.vivaelbirdos.com/rss/current.xml",
            linkAuthor: "https://www.vivaelbirdos.com/",
        },
        {
            id: 2,
            image: <img className={classes.cardImg} src="https://a.espncdn.com/combiner/i?img=/i/espn/networks_shows/500/undefeated.png?w=200&h=200" alt="" />,
            header: "The Undefeated: Sports",
            bodyText:
                "The Undefeated is the premier platform for exploring the intersections of race, sports and culture. We enlighten and entertain with innovative storytelling, original reporting and provocative commentary.",
            author: "The Undefeated",
            avatar: <img className={classes.avatarImg} src="https://theundefeated.com/wp-content/themes/espn-theundefeated/assets/images/the-undefeated-logo.png" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://theundefeated.com/sports/feed/",
            linkHeader: "https://readtronic.com/follow?url=https://theundefeated.com/sports/feed/",
            linkPlus: "https://readtronic.com/follow?url=https://theundefeated.com/sports/feed/",
            linkAuthor: "https://theundefeated.com/sports",
        },
        {
            id: 3,
            image: <img className={classes.cardImg} src="https://i1.wp.com/hinklehardwood.com/wp-content/uploads/2019/07/cropped-hinklehardwood-remove-bg-picture-2.png?w=391&ssl=1" alt="" />,
            header: "Hinkle Hardwood",
            bodyText:
                "Hinkle Hardwood is a Butler Bulldogs basketball fan blog dedicated to bringing the latest team news, recruiting updates, advanced statistics, and analysis to our visitors.",
            author: "Hinkle Hardwood",
            avatar: <img className={classes.avatarImg} src="https://i1.wp.com/hinklehardwood.com/wp-content/uploads/2019/07/cropped-hinklehardwood-remove-bg-picture-2.png?w=391&ssl=1" alt="" />,
            date: "",
            linkImg: "https://hinklehardwood.com/feed/",
            linkHeader: "https://hinklehardwood.com/feed/",
            linkPlus: "https://hinklehardwood.com/feed/",
            linkAuthor: "https://hinklehardwood.com/",
        },
    ]
    const TECH_DATA = [
        {
            id: 4,
            image: <img className={classes.cardImg} src="https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco/v1506577567/opo4m1agyevhzwd6dq2h.png" alt="" />,
            header: "Reforge Blog",
            bodyText:
                "Where Top Tech Comes to Scale",
            author: "Reforge",
            avatar: <img className={classes.avatarImg} src="https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco/v1506577567/opo4m1agyevhzwd6dq2h.png" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://www.reforge.com/blog?format=rss",
            linkHeader: "https://readtronic.com/follow?url=https://www.reforge.com/blog?format=rss",
            linkPlus: "https://readtronic.com/follow?url=https://www.reforge.com/blog?format=rss",
            linkAuthor: "https://www.reforge.com/",
        },
        {
            id: 1,
            image: <img className={classes.cardImg} src="https://pbs.twimg.com/profile_images/1325357290631823367/JjbL75U7_400x400.jpg" alt="" />,
            header: "Casey Accidental",
            bodyText:
                "Hi, I'm Casey Winters, Chief Product Officer at Eventbrite. I also advise tech companies on how to scale and sustainably grow their businesses.",
            author: "Casey Winters",
            avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/1325357290631823367/JjbL75U7_400x400.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://caseyaccidental.com/feed/",
            linkHeader: "https://readtronic.com/follow?url=https://caseyaccidental.com/feed/",
            linkPlus: "https://readtronic.com/follow?url=https://caseyaccidental.com/feed/",
            linkAuthor: "https://caseyaccidental.com/",
        },
        {
            id: 2,
            image: <img className={classes.cardImg} src="https://andrewchen.co/wp-content/uploads/2016/08/ahc_cropped.jpg" alt="" />,
            header: "@andrewchen",
            bodyText:
                "Long-form essays on what’s going on here in Silicon Valley.",
            author: "Andrew Chen",
            avatar: <img className={classes.avatarImg} src="https://andrewchen.co/wp-content/uploads/2016/08/ahc_cropped.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=http://andrewchen.com/feed/",
            linkHeader: "https://readtronic.com/follow?url=http://andrewchen.com/feed/",
            linkPlus: "https://readtronic.com/follow?url=http://andrewchen.com/feed/",
            linkAuthor: "http://andrewchen.com/",
        },
        {
            id: 3,
            image: <img className={classes.cardImg} src="https://images.squarespace-cdn.com/content/v1/528c45f9e4b06be250a9fe30/1388320379991-2ODV6045QG9Y1T2LW3YF/ke17ZwdGBToddI8pDm48kMl3vFqDcl1hBjYOd4i-Gh5Zw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7aXK0t8ahyzoOLFEHArbPTKxj3liU0t3Gk0lkY8ksBBOy6b7JPyeoB5lcUSfv3waLg/avatar.png?format=300w" alt="" />,
            header: "Brian Balfour",
            bodyText:
                "In-Depth Insights and Guides About Customer Acquistion And Growth",
            author: "Brian Balfour",
            avatar: <img className={classes.avatarImg} src="https://images.squarespace-cdn.com/content/v1/528c45f9e4b06be250a9fe30/1388320379991-2ODV6045QG9Y1T2LW3YF/ke17ZwdGBToddI8pDm48kMl3vFqDcl1hBjYOd4i-Gh5Zw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7aXK0t8ahyzoOLFEHArbPTKxj3liU0t3Gk0lkY8ksBBOy6b7JPyeoB5lcUSfv3waLg/avatar.png?format=300w" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://brianbalfour.com/essays?format=rss",
            linkHeader: "https://readtronic.com/follow?url=https://brianbalfour.com/essays?format=rss",
            linkPlus: "https://readtronic.com/follow?url=https://brianbalfour.com/essays?format=rss",
            linkAuthor: "https://brianbalfour.com/essays",
        },

        {
            id: 5,
            image: <img className={classes.cardImg} src="https://images.squarespace-cdn.com/content/v1/55f009c8e4b079158f302bab/1575026318546-GIT97M6EFFJ5C8TTDFB1/ke17ZwdGBToddI8pDm48kGDMxm8xtIPAap92LxS6dppZw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVHrUsSatoAVlCtiugSLfjH6Z7cKtv-3e_pyR2Oep0qJlGQ6l2WM7tn7mqHTODzkmeM/circles-footer.png" alt="" />,
            header: "The Happy Startup School",
            bodyText:
                "For entrepreneurs that want to do good, make money and be happy",
            author: "The Happy Startup School",
            avatar: <img className={classes.avatarImg} src="https://cdn-images-1.medium.com/max/458/1*y7TI3ClS7ZEgrpNFRCE0sg@2x.png" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://medium.com/feed/the-happy-startup-school",
            linkHeader: "https://readtronic.com/follow?url=https://medium.com/feed/the-happy-startup-school",
            linkPlus: "https://readtronic.com/follow?url=https://medium.com/feed/the-happy-startup-school",
            linkAuthor: "https://medium.com/the-happy-startup-school",
        },
        {
            id: 6,
            image: <img className={classes.cardImg} src="https://stratechery.com/wp-content/uploads/2018/03/stratechery-logomark.png" alt="" />,
            header: "Stratechery",
            bodyText:
                "Stratechery provides analysis of the strategy and business side of technology and media, and the impact of technology on society.",
            author: "Ben Thompson",
            avatar: <img className={classes.avatarImg} src="https://stratechery.com/wp-content/uploads/2015/05/BenPortrait-medium-1-200x300.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://stratechery.com/feed",
            linkHeader: "https://readtronic.com/follow?url=https://stratechery.com/feed",
            linkPlus: "https://readtronic.com/follow?url=https://stratechery.com/feed",
            linkAuthor: "https://stratechery.com/",
        },
    ]
    const BIOTECH_DATA = [
        {
            id: 1,
            image: <img className={classes.cardImg} src="https://vis.sciencemag.org/periodic-table/assets/favicon.png" alt="" />,
            header: "In the Pipeline",
            bodyText:
                "Derek Lowe's commentary on drug discovery and the pharma industry. An editorially independent blog from the publishers of Science Translational Medicine.",
            author: "Derek Lowe",
            avatar: <img className={classes.avatarImg} src="https://blogs.sciencemag.org/pipeline/wp-content/uploads/sites/2/2018/07/Derek-Lowe.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=http://blogs.sciencemag.org/pipeline/feed",
            linkHeader: "https://readtronic.com/follow?url=http://blogs.sciencemag.org/pipeline/feed",
            linkPlus: "https://readtronic.com/follow?url=http://blogs.sciencemag.org/pipeline/feed",
            linkAuthor: "http://blogs.sciencemag.org/pipeline",
        },
        {
            id: 3,
            image: <img className={classes.cardImg} src="http://www.antonycooke.com/wp-content/uploads/amc_logo_life_sci_vc_type.jpg" alt="" />,
            header: "Life Sci VC",
            bodyText:
                "Recovering scientist turned early stage VC A biotech optimist fighting gravity.",
            author: "Bruce Booth",
            avatar: <img className={classes.avatarImg} src="https://1zz10747jh9x3auhkivc05o5-wpengine.netdna-ssl.com/wp-content/uploads/2020/04/bruce-booth.jpg" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://lifescivc.com/feed/",
            linkHeader: "https://readtronic.com/follow?url=https://lifescivc.com/feed/",
            linkPlus: "https://readtronic.com/follow?url=https://lifescivc.com/feed/",
            linkAuthor: "https://lifescivc.com/",
        },
        {
            id: 4,
            image: <img className={classes.cardImg} src="https://cdn.substack.com/image/fetch/w_96,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2Fe01a60de-896f-4810-a041-55f578efc977_1280x1280.png" alt="" />,
            header: "Boutique Biotech",
            bodyText:
                "Welcome to Boutique Biotech, a high content low volume newsletter email digest reporting on unique biotech healthcare market insights not often covered in mainstream media outlets.",
            author: "Bohdan (Bo) Khomtchouk",
            avatar: <img className={classes.avatarImg} src="https://media-exp1.licdn.com/dms/image/C4E03AQHN1W0vSP_PWQ/profile-displayphoto-shrink_800_800/0/1593723085292?e=1620864000&v=beta&t=p-zkufHgKDAqxA807htUx-HMv5dX4Y85TS3dkntP-g8" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://boutiquebiotech.substack.com/feed/",
            linkHeader: "https://readtronic.com/follow?url=https://boutiquebiotech.substack.com/feed/",
            linkPlus: "https://readtronic.com/follow?url=https://boutiquebiotech.substack.com/feed/",
            linkAuthor: "https://www.linkedin.com/in/bohdankhomtchouk/",
        },
        /*{
            id: 2,
            image: <img className={classes.cardImg} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Blogger.svg/1200px-Blogger.svg.png" alt="" />,
            header: "Practical Fragments",
            bodyText:
                "This blog is meant to allow Fragment-based Drug Design Practitioners to get together and discuss NON-CONFIDENTIAL issues regarding fragments.",
            author: "Dan Erlanson",
            avatar: <img className={classes.avatarImg} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Blogger.svg/1200px-Blogger.svg.png" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=http://practicalfragments.blogspot.com/feeds/posts/default",
            linkHeader: "https://readtronic.com/follow?url=http://practicalfragments.blogspot.com/feeds/posts/default",
            linkPlus: "https://readtronic.com/follow?url=http://practicalfragments.blogspot.com/feeds/posts/default",
            linkAuthor: "http://practicalfragments.blogspot.com/",
        },*/
        /*{
            id: 3,
            image: <img className={classes.cardImg} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Blogger.svg/1200px-Blogger.svg.png" alt="" />,
            header: "Molecular Design",
            bodyText:
                "Controlling the behavior of compounds and materials by manipulation of molecular properties.",
            author: "Peter Kenny",
            avatar: <img className={classes.avatarImg} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Blogger.svg/1200px-Blogger.svg.png" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=http://fbdd-lit.blogspot.com/feeds/posts/default",
            linkHeader: "https://readtronic.com/follow?url=http://fbdd-lit.blogspot.com/feeds/posts/default",
            linkPlus: "https://readtronic.com/follow?url=http://fbdd-lit.blogspot.com/feeds/posts/default",
            linkAuthor: "http://fbdd-lit.blogspot.com",
        },*/
        /*{
            id: 3,
            image: <img className={classes.cardImg} src="https://s0.wp.com/wp-content/themes/pub/notesil/i/logo.gif" alt="" />,
            header: "Chemiotics II",
            bodyText:
                "Lotsa stuff, basically scientific — molecular biology, organic chemistry, medicine (neurology), math — and music",
            author: "https://s0.wp.com/wp-content/themes/pub/notesil/i/logo.gif",
            avatar: <img className={classes.avatarImg} src="https://s0.wp.com/wp-content/themes/pub/notesil/i/logo.gif" alt="" />,
            date: "",
            linkImg: "https://readtronic.com/follow?url=https://luysii.wordpress.com/feed/",
            linkHeader: "https://readtronic.com/follow?url=https://luysii.wordpress.com/feed/",
            linkPlus: "https://readtronic.com/follow?url=https://luysii.wordpress.com/feed/",
            linkAuthor: "https://luysii.wordpress.com/",
        },*/
        /*{
            id: 3,
            image: <img className={classes.cardImg} src="" alt="" />,
            header: "",
            bodyText:
                "",
            author: "",
            avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
            date: "",
            linkImg: "",
            linkHeader: "",
            linkPlus: "",
            linkAuthor: "",
        },*/
    ]


    const screenToShow = () => {
        switch (screen) {
            case "NEWS":
                return NEWS_DATA
            case "TRAVEL":
                return TRAVEL_DATA
            case "FOOD":
                return FOOD_DATA
            case "SPORTS":
                return SPORTS_DATA
            case "TECH":
                return TECH_DATA
            case "BIOTECH":
                return BIOTECH_DATA


            default:
                break
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.gridButtons}>
                <Button
                    className={
                        screen === "NEWS" ? classes.buttonActive : classes.button
                    }
                    onClick={() => setScreen("NEWS")}
                >
                    News
        </Button>
                <Button
                    className={
                        screen === "TRAVEL" ? classes.buttonActive : classes.button
                    }
                    onClick={() => setScreen("TRAVEL")}
                >
                    Travel
        </Button>

                <Button
                    className={
                        screen === "FOOD" ? classes.buttonActive : classes.button
                    }
                    onClick={() => setScreen("FOOD")}
                >
                    Food
        </Button>

                <Button
                    className={screen === "SPORTS" ? classes.buttonActive : classes.button}
                    onClick={() => setScreen("SPORTS")}
                >
                    Sports
        </Button>
                <Button
                    className={screen === "TECH" ? classes.buttonActive : classes.button}
                    onClick={() => setScreen("TECH")}
                >
                    Tech
        </Button>
                <Button
                    className={screen === "BIOTECH" ? classes.buttonActive : classes.button}
                    onClick={() => setScreen("BIOTECH")}
                >
                    Biotech
        </Button>

            </div>

            <div className={classes.gridCards}>
                {screenToShow().map(
                    ({
                        id,
                        image,
                        header,
                        bodyText,
                        author,
                        avatar,
                        date,
                        linkImg,
                        linkHeader,
                        linkPlus,
                        linkAuthor,
                    }) => (
                        <div key={id}>
                            <FollowCard
                                image={image}
                                header={header}
                                bodyText={bodyText}
                                author={author}
                                avatar={avatar}
                                date={date}
                                linkImg={linkImg}
                                linkHeader={linkHeader}
                                linkPlus={linkPlus}
                                linkAuthor={linkAuthor}
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    )
}
