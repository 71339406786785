import React from "react";
import Typography from "@material-ui/core/Typography";
import { SectionArticle } from './SectionArticle';
import { makeStyles } from "@material-ui/core/styles";
import BGMain from "./images/BGMain.svg";
import BGMobile from "./images/BGMobile.svg";

const sections = [

    {
        title: "OKR: Objectives & Key Results",
        articles: [
            {
                title: "Measure what matters: summary",
                link: "https://www.davidtuite.com/measure-what-matters-notes/#:~:text=Measure%20What%20Matters%20by%20John,book%20is%20about%20OKRs%20themselves",
            },
            {
                title: "How to Set Good OKRs With Examples",
                link: "https://www.youtube.com/watch?v=qYAnz1PwpRA",
            },
            {
                title: "Why the secret to success is setting the right goals | John Doerr",
                link: "https://www.youtube.com/watch?v=L4N1q4RNi9I&t=69s",
            },

        ],
    },
    {
        title: "Sound Strategies for Monetization",
        articles: [
            {
                title: "The Model Market Fit Threshold & What it Means for Your Growth Strategy",
                link: "https://brianbalfour.com/essays/model-market-fit-threshold-for-growth",
            },
            {
                title: "Pricing Methods: 4 Choices & How to Choose the Right Product Pricing Method",
                link: "https://www.profitwell.com/recur/all/pricing-methods",
            },
            {
                title: "Startup Business Models",
                link: "https://www.dkparker.com/startup_business_models/",
            },

        ],
    },
    {
        title: "Networking on LinkedIn like a Pro",
        articles: [
            {
                title: "Four Tips to Network on LinkedIn",
                link: "https://blog.linkedin.com/2020/may/june/12/four-tips-to-network-on-linkedin",
            },
        ],
    },
    {
        title: "Build Deep Dive: Make it Theoretically Profitable",
        articles: [
            {
                title: "Intro to Startup Metrics",
                link: "https://www.startuphacks.vc/blog/2016/04/18/startup-metrics-series-the-basics",
            },
            {
                title: "Tracking Plan: How This Document is the Backbone of a Successful Analytics Strategy",
                link: "https://practicoanalytics.com/tracking-plan/",
            },
            {
                title: "Building A Startup Financial Model That Works",
                link: "https://visible.vc/blog/financial-modeling-for-startups/",
            },

        ],
    },
    {
        title: "Metrics: Building Products that are Made to Measure",
        articles: [
            {
                title: "Book Summary: Lean Analytics",
                link: "https://medium.com/dataseries/book-summary-lean-analytics-6a0d9b584da9",
            },
            {
                title: "Story Telling with Story Mapping",
                link: "http://cmforagile.blogspot.com/2015/07/story-telling-with-story-mapping.html",
            },
            {
                title: "Make It Count – A Guide to Measuring the User Experience",
                link: "https://www.toptal.com/designers/ux/measuring-the-user-experience",
            },

        ],
    },
    {
        title: "Founder's Equity & Dilution",
        articles: [
            {
                title: "WHAT IS A CAP TABLE, WHY DO YOU NEED ONE AND WHAT SHOULD IT LOOK LIKE?",
                link: "https://www.cooleygo.com/what-is-a-cap-table/",
            },
            {
                title: "What’s A Reasonable Starting Point For An Option Pool?",
                link: "https://www.venturedeals.com/whats-a-reasonable-starting-point-for-an-option-pool/",
            },
        ],
    },
    {
        title: "Financial Modeling",
        articles: [
            {
                title: "Financial Modeling for startups: the spreadsheet that made us profitable",
                link: "https://slidebean.medium.com/financial-modeling-for-startups-the-spreadsheet-that-made-us-profitable-b0f1f1e3c1bb",
            },
        ],
    },
    {
        title: "Financial Modeling",
        articles: [
            {
                title: "Why Product Market Fit Isn't Enough",
                link: "https://brianbalfour.com/essays/product-market-fit-isnt-enough",
            },
            {
                title: "Your Startup as a Funnel",
                link: "https://www.startuphacks.vc/blog/2019/08/09/your-startup-as-a-funnel",
            },
            {
                title: "The Superhuman Product/Market Fit Engine",
                link: "https://coda.io/@rahulvohra/superhuman-product-market-fit-engine",
            },
            {
                title: "How Superhuman Built an Engine to Find Product/Market Fit",
                link: "https://firstround.com/review/how-superhuman-built-an-engine-to-find-product-market-fit/",
            },
        ],
    },

];

const useStyles = makeStyles((theme) => ({
    avatarContainer: {
        display: "flex",
        flexDirection: "column",
    },
    root: {
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 122,
        backgroundImage: `url(${BGMain})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",

        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${BGMobile})`,
        },
    },
    gridContainer: {},
    textContainer: {},
    avatar: {
        margin: "118px auto 0px",
    },
    name: {
        fontFamily: "Fira Sans",
        fontSize: 17,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        textAlign: "center",
    },
    date: {
        fontFamily: "Fira Sans",
        fontSize: 15,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
        textAlign: "center",
        marginBottom: 94,

        [theme.breakpoints.down("sm")]: {
            marginBottom: 24,
        },
    },
    header: {
        fontSize: 37,
        margin: "24px 7px 30px 7px",

        [theme.breakpoints.down("sm")]: {
            fontSize: 22,
            textAlign: "center",
            margin: "24px 7px 13px 7px",
        },
    },
    text: {
        margin: "10px 7px 15px 7px",
        fontFamily: "Fira Sans",
        fontSize: 17,
        lineHeight: 1.7,
        letterSpacing: "normal",
        color: "#60656f",
    },
    mobileAvatarContainer: {
        display: "flex",
    },
    mobileNameContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "auto 17px",
    },
    mobileAvatar: {
        marginLeft: 5,
    },
    mobileName: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        lineHeight: 2,
        color: "#000",
    },
    mobileDate: {
        fontFamily: "Fira Sans",
        fontSize: 16,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: "#b3b8b3",
    },
}));


export const Build = () => {
    const classes = useStyles();
    var sectionElements = sections.map((s) =>
        <Typography className={classes.text} variant="body1" >
            <h2>{s.title}</h2>
            {s.articles.map((a) => <SectionArticle title={a.title} link={a.link} />)}
        </ Typography>);
    return (
        <>
            {sectionElements}
        </>
    );
}