import { makeStyles } from "@material-ui/core/styles";
import BGMain from "../images/BGMain.svg";
import BGMobile from "../images/BGMobile.svg";

export const useStyles = makeStyles((theme) => ({
    root: {
      height: 1035,
      backgroundImage: `url(${BGMain})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      marginBottom: 330,
  
      [theme.breakpoints.down("sm")]: {
        backgroundImage: `url(${BGMobile})`,
      },
    },
    wrapper: {
      borderRadius: 20,
      boxShadow: "0 20px 80px 0 rgba(117, 126, 117, 0.11)",
      backgroundColor: "#fff",
      margin: 20,
    },
    uploadBox: {
      display: "flex",
      width: "85%",
      margin: "auto",
      minHeight: 265,
      borderRadius: 5,
      backgroundColor: "#f6f7fe",
    },
    dropBox: {
      display: "flex",
      flexDirection: "column",
      width: "95%",
      margin: "auto",
      minHeight: 225,
      borderRadius: 14,
      border: "dashed 1px rgba(117, 126, 117, 0.3)",
    },
    headerTextBox: {
      opacity: 0.55,
      fontFamily: "Fira Sans",
      fontSize: 20,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#757e75",
    },
    orText: {
      opacity: 0.5,
      fontFamily: "Fira Sans",
      fontSize: 20,
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.5,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#4279f1",
    },
    uploadFakeButton: {
      display: "flex",
      margin: "auto",
      width: 190,
      height: 62,
      borderRadius: 5,
      backgroundColor: "rgb(66,121,241,0.04)",
    },
    fakeButtonText: {
      margin: "auto",
      fontFamily: "Fira Sans",
      fontSize: 16,
      fontWeight: "bold",
      letterSpacing: "-0.64px",
      color: "#4279f1",
    },
    textFakeButtonContainer: {
      display: "flex",
      margin: "auto",
    },
    uploadIcon: {
      marginRight: 10,
    },
    stepSubmitContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "5%",
  
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    submitContainer: {
      padding: 24,
  
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      borderRadius: 20,
    },
    headerImgContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "left",
    },
    headerText: {
      fontSize: 26,
      fontWeight: "bold",
      lineHeight: 2,
      letterSpacing: "-1.04",
      textAlign: "left",
      color: "#292d34",
      margin: "auto 0",
    },
    headerImg: {
      width: 60,
      marginLeft: "7%",
    },
    multiText: {
      backgroundColor: "#f6f7fe",
      borderRadius: 20,
  
      "& .MuiInput-underline:after": {
        borderBottomColor: "#fff",
      },
  
      "& .MuiInput-underline:before": {
        borderBottomColor: "#fff",
      },
    },
    submitButton: {
      width: 219,
    },
  }));