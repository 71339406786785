import React, { useCallback, useState, useContext } from "react";
import Text from "../images/text.svg";
import { useStyles } from "./styles";
import FirebaseContext from "../Firebase";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { HorizontalStepper } from "../components/HorizontalStepper";
import Button from "@material-ui/core/Button";
import { ReadtronicClient } from "../clients/ReadtronicClient";

const steps = {
    START: 0,
    HAVE_TEXT: 1,
    CONVERTING: 2,
    DONE: 3,
}

export const UploadText = () => {
  const firebase = useContext(FirebaseContext);
  const readtronic = new ReadtronicClient(firebase);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [text, setText] = useState("");

  const upload = async () => {
      setActiveStep(steps.CONVERTING);
      await readtronic.uploadText("default", text);
      setActiveStep(steps.DONE);
      setText("");

      setTimeout(() => {
          setActiveStep(steps.START);
      }, 1000);
  };

  const textChanged = useCallback((event) => {
    const txt = event.target.value;
    setText(txt);
    if (txt && txt.length !== 0) {
        setActiveStep(steps.HAVE_TEXT);
    } else {
        setActiveStep(steps.START);
    }
  }, []);

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerImgContainer}>
          <img className={classes.headerImg} src={Text} alt="" />
          <Typography className={classes.headerText} variant="body1">
            Text to audio
          </Typography>
        </div>
        <br />
      </div>

      <div className={classes.uploadBox}>
        <TextField
          className={classes.multiText}
          fullWidth
          multiline
          rows={13}
          value={text}
          placeholder=" Write or Paste any text here. (The first line will be the title)"
          onChange={textChanged}
          disabled={!([steps.START, steps.HAVE_TEXT].includes(activeStep))}
          data-testid="text"
        />
      </div>

      <div className={classes.stepSubmitContainer}>
        <HorizontalStepper
          steps={["Enter", "Convert", "Done"]}
          activeStep={activeStep}
        />
        <div className={classes.submitContainer}>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            onClick={() => {
              upload();
            }}
            disabled={!(activeStep === steps.HAVE_TEXT)}
            data-testid="button"
          >
            Convert
          </Button>
        </div>
      </div>
    </>
  );
};
