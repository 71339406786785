import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"

import PlaylistAdd from "../images/playlistadd.svg"

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  gridContainer: {
    display: "flex",
    flex: 1,
    marginBottom: 24,
    borderBottom: '0.5px solid #E0E0E0',
    paddingBottom: 17,
  },
  headerText: {
    fontFamily: "Lato",
    fontSize: 16,
    lineHeight: 1.6,
    textAlign: "left",
    color: "#292929",
  },
  bodyText: {
    fontFamily: "Lato",
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
    textAlign: "left",
  },
  authorDateContainer: {
    display: "flex",
    marginTop: 8,
    height: 25,
    borderRadius: 25,
  },
  authorDateText: {
    fontFamily: "Lato",
    fontSize: 14,
    textAlign: "left",
    lineHeight: 1.14,
    color: "#292929",
  },
  authorDateLink: {
    margin: "auto 0",
    textDecoration: "none",
  },
  plusButton: {
    paddingBottom: 0,
  },
  leftContainer: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
  },
  imgLink: {
    margin: "0 auto",
    cursor: "pointer",
  },
  link: {
    overflow: "hidden",
    textDecoration: "none",
    cursor: "pointer",
    radius: 10,
  },
}))

export const PlaylistCard = ({
  image,
  header,
  bodyText,
  author,
  avatar,
  date,
  linkImg,
  linkHeader,
  linkPlus,
  linkAuthor,
}) => {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth={false}>
      <Grid className={classes.gridContainer} spacing={0} container>
        <Grid className={classes.leftContainer} item xs={3} sm={3}>
          <a
            className={classes.imgLink}
            href={linkImg}
            target="_blank"
            rel="noopener noreferrer"
          >
            {image}
          </a>

          <IconButton
            className={classes.plusButton}
            component={Link}
            href={linkPlus}
            target="blank"
          >
            <img src={PlaylistAdd} alt="Istore" />
          </IconButton>
        </Grid>
        <Grid className={classes.leftContainer} item xs={9} sm={9}>
          <a
            href={linkHeader}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography className={classes.headerText} variant="body1">
              {header}
            </Typography>
          </a>

          <Typography className={classes.bodyText} variant="body1">
            {bodyText}
          </Typography>

          <div className={classes.authorDateContainer}>
            {avatar}

            <a
              href={linkAuthor}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.authorDateLink}
            >
              <Typography className={classes.authorDateText} variant="body1">
                {author} | {date}
              </Typography>
            </a>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
