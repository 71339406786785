import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

import { PlaylistCard } from "./components/PlaylistCard"

import Kevin from "./images/kevinSerious.jpg"

import BGMain from "./images/BGMain.svg"
import BGMobile from "./images/BGMobile.svg"

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: 15,
    paddingBottom: 122,
    backgroundImage: `url(${BGMain})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${BGMobile})`,
    },
  },
  gridContainer: {},
  gridCards: {
    display: "flex",
    flexDirection: "column",
    marginTop: 15,
  },
  text: {
    fontFamily: "Fira Sans",
    fontSize: 17,
    lineHeight: 1.7,
    letterSpacing: "normal",
    color: "#60656f",
  },
  cardImg: {
    margin: "0 auto",
    hight: 60,
    width: 60,
    borderRadius: 3,
    backgroundColor: 'white',
  },
  avatarImg: {
    marginRight: 8,
    hight: 25,
    width: 25,
    borderRadius: 25,
  },
  gridButtons: {
    display: "flex",
    marginTop: 5,
  },
  button: {
    fontFamily: "Lato",
    borderRadius: 0,
    padding: 0,
    backgroundColor: "rgba(96, 101, 111, 0.06)",

    "& .MuiButton-label": {
      fontSize: 19,
    },
  },
  buttonActive: {
    fontFamily: "Lato",
    borderRadius: 0,
    padding: 0,
    fontWeight: "bold",
    borderBottom: "solid 2px #292929",

    "& .MuiButton-label": {
      fontSize: 19,
    },
  },
}))

export const Playlist = () => {
  const [screen, setScreen] = useState("BUZZ")
  const classes = useStyles()

  const BUZZ_DATA = [
    /*
    {
      id: 1,
      image: <img className={classes.cardImg} src="https://images.unsplash.com/photo-1608243499644-c55bd9f2b837?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=872&q=80" alt="" />,
      header: "J&J Vaccine",
      bodyText:
        "The J&J vaccine is a game changer.",
      author: "Kevin Gaffney",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "",
      linkImg: "",
      linkHeader: "",
      linkPlus: "",
      linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
    },
    */
    {
      id: 2,
      image: <img className={classes.cardImg} src="https://images.unsplash.com/flagged/photo-1562599838-8cc871c241a5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" alt="" />,
      header: "What the ____ are NFTs?",
      bodyText:
        "I somehow ended up in NFT TikTok and had no idea what they were talking about, so I listened to these articles to get up-to-speed. It may seem redundant, but listening to them back-to-back really helped me wrap my head around this as each had a slightly different take.",
      author: "Kevin Gaffney",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/NFTs.json",
      linkHeader: "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/NFTs.json",
      linkPlus: "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/NFTs.json",
      linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
    },
    {
      id: 3,
      image: <img className={classes.cardImg} src="https://images.unsplash.com/photo-1560223715-a7066e66f501?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" alt="" />,
      header: "Pidgeon Sports of NYC",
      bodyText:
        "Dig into the culture of raising and training pidgeon athletes on the rooftops of NYC.",
      author: "Kevin Gaffney",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/pidgeons-nyc.json",
      linkHeader: "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/pidgeons-nyc.json",
      linkPlus: "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/pidgeons-nyc.json",
      linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
    },
    /*{
      id: 4,
      image: <img className={classes.cardImg} src="https://images.unsplash.com/photo-1596162955779-9c8faa3d4e3c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=803&q=80" alt="" />,
      header: "What the ____ is a SPAC?",
      bodyText:
        "Special purpose acquisition company (SPAC): Everyone's talking about them.  What are they and should you care?",
      author: "",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "",
      linkImg: "",
      linkHeader: "",
      linkPlus: "",
      linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
          },*/
    /*{
        id: 5,
        image: <img className={classes.cardImg} src="" alt="" />,
        header: "",
        bodyText:
          "",
        author: "",
        avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
        date: "",
        linkImg: "",
        linkHeader: "",
        linkPlus: "",
        linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
      },*/
  ]

  const SPORTS_DATA = [
    {
      id: 1,
      image: <img className={classes.cardImg} src="https://pbs.twimg.com/profile_images/1131311720427868161/EzCgF37L_400x400.png" alt="" />,
      header: "St. Louis Cardinals",
      bodyText:
        "St. Louis Post-Dispatch's coverage of the Cardinals",
      author: "",
      avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/1131311720427868161/EzCgF37L_400x400.png" alt="" />,
      date: "St. Louis Post-Dispatch",
      linkImg: "https://readtronic.com/playlist?url=https://www.stltoday.com/search/?c=sports/baseball/professional*&d1=&d2=&s=start_time&sd=desc&l=100&f=rss&t=article,link,html,collection",
      linkHeader: "https://readtronic.com/playlist?url=https://www.stltoday.com/search/?c=sports/baseball/professional*&d1=&d2=&s=start_time&sd=desc&l=100&f=rss&t=article,link,html,collection",
      linkPlus: "https://readtronic.com/playlist?url=https://www.stltoday.com/search/?c=sports/baseball/professional*&d1=&d2=&s=start_time&sd=desc&l=100&f=rss&t=article,link,html,collection",
      linkAuthor: "https://www.stltoday.com/sports/baseball/professional/",
    },
    {
      id: 2,
      image: <img className={classes.cardImg} src="https://images2.minutemediacdn.com/image/fetch/c_fill,f_auto,w_150,h_150/https://fansided.com/wp-content/assets/site_images/cardinals_mlb/logo_redbirdrants-com__150x150.png" alt="" />,
      header: "Redbird Rants",
      bodyText:
        "Welcome to Redbird Rants, the St. Louis Cardinals news and opinions site brought to you by the FanSided Network.",
      author: "Redbird Rants",
      avatar: <img className={classes.avatarImg} src="https://images2.minutemediacdn.com/image/fetch/c_fill,f_auto,w_150,h_150/https://fansided.com/wp-content/assets/site_images/cardinals_mlb/logo_redbirdrants-com__150x150.png" alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://redbirdrants.com/feed/",
      linkHeader: "https://readtronic.com/playlist?url=https://redbirdrants.com/feed/",
      linkPlus: "https://readtronic.com/playlist?url=https://redbirdrants.com/feed/",
      linkAuthor: "https://redbirdrants.com/",
    },
    {
      id: 3,
      image: <img className={classes.cardImg} src="http://1.gravatar.com/avatar/4846d7ca62f25c96e2e132ee1ce1685d" alt="" />,
      header: "The Cardinal Conclave",
      bodyText:
        "The Cardinal Conclave is a place for thoughtful and intelligent discussion of the St. Louis Cardinals. Different points of view are encouraged as long as they are backed up with reason and logic.",
      author: "The Cardinal Conclave ",
      avatar: <img className={classes.avatarImg} src="http://0.gravatar.com/avatar/7d949786905765c6dfcad627ae0fd15f" alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://www.cardsconclave.com/feed/",
      linkHeader: "https://readtronic.com/playlist?url=https://www.cardsconclave.com/feed/",
      linkPlus: "https://readtronic.com/playlist?url=https://www.cardsconclave.com/feed/",
      linkAuthor: "https://readtronic.com/playlist?url=https://www.cardsconclave.com/",
    }

  ]
  const TECH_DATA = [
    {
      id: 1,
      image: <img className={classes.cardImg} src="https://cdn-images-1.medium.com/max/814/1*pempph-OAqpRKG5v1-yisg@2x.png" alt="" />,
      header: "The Startup",
      bodyText:
        "Medium's largest active publication, followed by +775K people.",
      author: "The Startup",
      avatar: <img className={classes.avatarImg} src="https://cdn-images-1.medium.com/max/814/1*pempph-OAqpRKG5v1-yisg@2x.png" alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://medium.com/feed/swlh",
      linkHeader: "https://readtronic.com/playlist?url=https://medium.com/feed/swlh",
      linkPlus: "https://readtronic.com/playlist?url=https://medium.com/feed/swlh",
      linkAuthor: "https://medium.com/swlh",
    },
    {
      id: 2,
      image: <img className={classes.cardImg} src="https://assets.website-files.com/5df39869d6a5f9c1a5cb1939/5dfa5a82c967d2d4ccd99425_9ddb6b58-2b79-43af-9677-023d92c0d391.png" alt="" />,
      header: "First Round Review",
      bodyText:
        "The Review is about liberating this knowledge to inspire and accelerate action.",
      author: "First Round Capital",
      avatar: <img className={classes.avatarImg} src="https://miro.medium.com/max/3150/1*Wo8fUWfa-nCFxNah4JZdXw.jpeg" alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://review.firstround.com/feed.xml",
      linkHeader: "https://readtronic.com/playlist?url=https://review.firstround.com/feed.xml",
      linkPlus: "https://readtronic.com/playlist?url=https://review.firstround.com/feed.xml",
      linkAuthor: "https://review.firstround.com/",
    },
  ]
  const BIOTECH_DATA = [
    {
      id: 1,
      image: <img className={classes.cardImg} src="https://pbs.twimg.com/profile_images/1247897788702052354/8g5XsbZF_400x400.jpg" alt="" />,
      header: "BioPharma Dive",
      bodyText:
        "BioPharma Dive provides in-depth journalism and insight into the most impactful news and trends shaping biotech and pharma.",
      author: "Industry Dive",
      avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/1247897788702052354/8g5XsbZF_400x400.jpg" alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://www.biopharmadive.com/feeds/news/",
      linkHeader: "https://readtronic.com/playlist?url=https://www.biopharmadive.com/feeds/news/",
      linkPlus: "https://readtronic.com/playlist?url=https://www.biopharmadive.com/feeds/news/",
      linkAuthor: "https://readtronic.com/playlist?url=https://www.biopharmadive.com/",
    },
    {
      id: 5,
      image: <img className={classes.cardImg} src="https://i2.wp.com/drughunter.com/wp-content/uploads/2021/02/cropped-2021-02-Temporary-Logo-01-2.png?fit=626%2C154&ssl=1" alt="" />,
      header: "Drug Hunter",
      bodyText:
        "Drug Hunter is a trusted, independent source of curated, factual information for the drug discovery community. Our goal is to provide an inclusive platform that empowers, educates, and connects professional drug hunters, and celebrates the important work being done by the heroes in our industry.",
      author: "Dennis Hu",
      avatar: <img className={classes.avatarImg} src="https://pbs.twimg.com/profile_images/1352646008182697984/jLK50C2X_400x400.jpg" alt="" />,
      date: "",
      linkImg: "https://readtronic.com/playlist?url=https://drughunter.com/feed/",
      linkHeader: "https://readtronic.com/playlist?url=https://drughunter.com/feed/",
      linkPlus: "https://readtronic.com/playlist?url=https://drughunter.com/feed/",
      linkAuthor: "https://drughunter.com/",
    },
    /*{
      id: 3,
      image: <img className={classes.cardImg} src="" alt="" />,
      header: "",
      bodyText:
        "",
      author: "",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "",
      linkImg: "",
      linkHeader: "",
      linkPlus: "",
      linkAuthor: "",
    },
    {
      id: 5,
      image: <img className={classes.cardImg} src="" alt="" />,
      header: "",
      bodyText:
        "",
      author: "",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "",
      linkImg: "",
      linkHeader: "",
      linkPlus: "",
      linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
    },*/
  ]

  const PYROS_DATA = [
    {
      id: 1,
      image: (
        <img
          className={classes.cardImg}
          src="https://1871.com/wp-content/uploads/2020/01/1871-pyros-icons_explore-150x150.png"
          alt=""
        />
      ),
      header: "Explore",
      bodyText:
        "You’ve got an idea or early stage product that you’re passionate about. You can’t wait to test it.",
      author: "",
      avatar: (
        <img
          className={classes.avatarImg}
          src="https://1871.com/wp-content/uploads/2020/01/1871-DC-brandAssetsPlateLogo_plateLogoBlueSteel.png"
          alt=""
        />
      ),
      date: "Chort 1 - 2021",
      linkImg:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Explore-Q1-2021.json",
      linkHeader:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Explore-Q1-2021.json",
      linkPlus:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Explore-Q1-2021.json",
      linkAuthor: "https://1871.com/membership/pyros/",
    },
    {
      id: 2,
      image: (
        <img
          className={classes.cardImg}
          src="https://1871.com/wp-content/uploads/2020/01/1871-pyros-icons_build-150x150.png"
          alt=""
        />
      ),
      header: "Build",
      bodyText:
        "You’ve validated and deeply understand a problem worth solving, including the business model to prove it. Your product is in customers’ hands with early market signs that you’re on the right track.",
      author: "",
      avatar: (
        <img
          className={classes.avatarImg}
          src="https://1871.com/wp-content/uploads/2020/01/1871-DC-brandAssetsPlateLogo_plateLogoBlueSteel.png"
          alt=""
        />
      ),
      date: "Chort 1 - 2021",
      linkImg:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Build-Q1-2021.json",
      linkHeader:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Build-Q1-2021.json",
      linkPlus:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Build-Q1-2021.json",
      linkAuthor: "https://1871.com/membership/pyros/",
    },
    {
      id: 3,
      image: (
        <img
          className={classes.cardImg}
          src="https://1871.com/wp-content/uploads/2020/01/1871-pyros-icons_launch-150x150.png"
          alt=""
        />
      ),
      header: "Launch",
      bodyText:
        "Customers love your product and you are ready to take off. You’ve got the data to show it and a robust analytics funnel to analyze the results of growth tests.",
      author: "",
      avatar: (
        <img
          className={classes.avatarImg}
          src="https://1871.com/wp-content/uploads/2020/01/1871-DC-brandAssetsPlateLogo_plateLogoBlueSteel.png"
          alt=""
        />
      ),
      date: "Chort 1 - 2021",
      linkImg:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Launch-Q1-2021.json",
      linkHeader:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Launch-Q1-2021.json",
      linkPlus:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/PYROS/Launch-Q1-2021.json",
      linkAuthor: "https://1871.com/membership/pyros/",
    },
    {
      id: 4,
      image: (
        <img
          className={classes.cardImg}
          src="https://images.unsplash.com/photo-1528911767216-351e4afc2a93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
          alt=""
        />
      ),
      header: "Product-Market Fit",
      bodyText:
        "I've been trying to wrap my head around product-market fit and these articles have helped me slowly start to understand it.",
      author: "Kevin Gaffney",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "Updated Mar 3, 2021",
      linkImg:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/Product-Market-Fit.json",
      linkHeader:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/Product-Market-Fit.json",
      linkPlus:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/Product-Market-Fit.json",
      linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
    },
    {
      id: 5,
      image: (
        <img
          className={classes.cardImg}
          src="https://images.unsplash.com/photo-1455826581186-3031bc66471d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
          alt=""
        />
      ),
      header: "Increase User Retention: Hooked Model",
      bodyText:
        "We've been having some issues with retention,so I've been exploring the hooked model to identify helpful ways to get users to see the full value of our product. Hopefully these will help you too!",
      author: "Kevin Gaffney",
      avatar: <img className={classes.avatarImg} src={Kevin} alt="" />,
      date: "Updated Mar 3, 2021",
      linkImg:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/Hooked-Model.json",
      linkHeader:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/Hooked-Model.json",
      linkPlus:
        "https://readtronic.com/playlist?url=https://readtronic-dev-static-assets.s3-us-west-2.amazonaws.com/rss/Folders/Hooked-Model.json",
      linkAuthor: "https://www.linkedin.com/in/kevin-gaffney-a17b9a47/",
    },
  ]

  const screenToShow = () => {
    switch (screen) {
      case "BUZZ":
        return BUZZ_DATA
      case "SPORTS":
        return SPORTS_DATA
      case "TECH":
        return TECH_DATA
      case "BIOTECH":
        return BIOTECH_DATA
      case "PYROS":
        return PYROS_DATA
      default:
        break
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.gridButtons}>
        <Button
          className={screen === "BUZZ" ? classes.buttonActive : classes.button}
          onClick={() => setScreen("BUZZ")}
        >
          Buzz
        </Button>
        <Button
          className={screen === "SPORTS" ? classes.buttonActive : classes.button}
          onClick={() => setScreen("SPORTS")}
        >
          Sports
        </Button>
        <Button
          className={screen === "TECH" ? classes.buttonActive : classes.button}
          onClick={() => setScreen("TECH")}
        >
          Tech
        </Button>
        <Button
          className={screen === "BIOTECH" ? classes.buttonActive : classes.button}
          onClick={() => setScreen("BIOTECH")}
        >
          Biotech
        </Button>
        <Button
          className={
            screen === "PYROS" ? classes.buttonActive : classes.button
          }
          onClick={() => setScreen("PYROS")}
        >
          Pyros
        </Button>
      </div>

      <div className={classes.gridCards}>
        {screenToShow().map(
          ({
            id,
            image,
            header,
            bodyText,
            author,
            avatar,
            date,
            linkImg,
            linkHeader,
            linkPlus,
            linkAuthor,
          }) => (
            <div key={id}>
              <PlaylistCard
                image={image}
                header={header}
                bodyText={bodyText}
                author={author}
                avatar={avatar}
                date={date}
                linkImg={linkImg}
                linkHeader={linkHeader}
                linkPlus={linkPlus}
                linkAuthor={linkAuthor}
              />
            </div>
          )
        )}
      </div>
    </div>
  )
}
